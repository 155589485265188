<p-menu #menu [popup]="true" [model]="languages" appendTo="body"></p-menu>
<div [ngClass]="{ 'hide-primary-color': isLoggedIn }">
  <button class="p-link" (click)="menu.toggle($event)">
    <img src="assets/svg_icons/language_icon.svg" />
    <span class="position-relative language-initials">
      {{ lang | slice : 0 : 2 | uppercase }}
    </span>
    <!-- <i class="pi pi-angle-down position-relative language-caret"></i> -->
    <img src="assets/svg_icons/expand_icon.svg" />
  </button>
</div>
