import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { GridApi, GridOptions, ITooltipParams, MenuItemDef } from 'ag-grid-community';
import { Observable, Subject } from 'rxjs';

import { CellRendererService } from '@app/core/resources/cell-renderer.service';
import { FilterParamsService } from '@app/core/resources/filter-params.service';
import { FilterTextWhitespacesService } from '@app/core/resources/filter-text-whitespaces.service';
import { FilterTypeService } from '@app/core/resources/filter-type.service';
import { PaginationAggridFlexService } from '@app/core/resources/pagination-aggrid-flex.service';
import { SearchService } from '@app/layout/services/search.service';
import { ColumnDef, GroupedData } from '@app/report/interface/report-config';
import { HeadersService } from '@app/report/services/headers.service';
import { ReportService } from '@app/report/services/report.service';
import { ReportPermissionService } from '@app/report/services/reports.permission.service';
import { RowSpanHelperService } from '@app/report/services/row-span-helper.service';

@Component({
  selector: 'app-drill-down-reports',
  templateUrl: './drill-down-reports.component.html',
  styleUrls: ['./drill-down-reports.component.scss'],
})
export class DrillDownReportsComponent implements OnInit {
  @Output() closeClicked = new EventEmitter<boolean>();

  @Input() reportId: any;

  @Input() filterMetricName!: string;

  @Input() filterValue: any;

  @ViewChild('agGrid', { read: ElementRef }) gridElementRef!: ElementRef;

  headers: any[] = [];

  reportData: any = [];

  originalHeaders!: any[];

  isAddEnabled = false;

  haveFilters = true;

  grid!: GridApi;

  routeFilters: any = null; // For Global Seach handling

  actualValuesMap: any;

  isFirstTimeCall = false;

  isUpload = false;

  gridOptions: GridOptions = {
    alwaysMultiSort: true,
    headerHeight: 30,
    groupHeaderHeight: 40,
    rowHeight: 30,
    defaultColDef: { resizable: true },

    overlayNoRowsTemplate: '<span class="ag-overlay-no-rows-center">No records found</span>',
    preventDefaultOnContextMenu: true, // to prevent the browser default context menu,
    getContextMenuItems: data => {
      return this.getContextMenuItems(data);
    }, // custom context menu,
    groupHideOpenParents: true,
    isServerSideGroupOpenByDefault: () => true,
    suppressAggFuncInHeader: true, // headers won't include the aggFunc name (eg-sum)
    pagination: true,
    paginationPageSize: 50,
    paginationPageSizeSelector: [50, 100, 200],
    maxBlocksInCache: 0,
    cacheBlockSize: 50,
    serverSidePivotResultFieldSeparator: '_',
    pivotMode: false,

    rowModelType: 'serverSide',
    rowBuffer: 200,
    rowGroupPanelShow: 'onlyWhenGrouping',
    pivotPanelShow: 'onlyWhenPivoting',
    columnDefs: this.headers,

    onFirstDataRendered(params) {
      params.api.setGridOption('defaultColDef', { autoHeight: true });
    },
    onColumnPivotModeChanged: event => {
      this.onColumnPivotModeChanged(event);
    },
    getMainMenuItems: () => {
      return this.getMainMenuItems();
    }, // custom main column header menu
    onGridReady: this.onGridReady.bind(this),
  };

  private gridReady$ = new Subject<boolean>();

  constructor(
    private reportService: ReportService,
    private headerService: HeadersService,
    private filterTypeService: FilterTypeService,
    private filterParams: FilterParamsService,
    private cellRendererService: CellRendererService,
    private rowSpanHelperService: RowSpanHelperService,
    private searchService: SearchService,
    private paginationService: PaginationAggridFlexService,
    private reportPermissionService: ReportPermissionService,
    private filterTextWhitespacesService: FilterTextWhitespacesService
  ) {
    this.reportService.updatedReport.subscribe((res: any) => {
      if (res) {
        this.grid.refreshServerSide();
      }
    });
  }

  ngOnInit(): void {
    this.getHeadersData();
  }

  getHeadersData(): void {
    const paramsInfo = {
      id: this.reportId,
      numberOfMonth: '6',
    };

    const headers = this.headerService.getReportHeaders(Number(this.reportId), paramsInfo) as Observable<any>;

    headers.subscribe(res => {
      this.headers = res.map((item: any) => this.headerFormulate(item));
      if (res && res.length > 0) {
        const matricFieldName = res.find((r: any) => {
          return r.displayName === 'Metric';
        })?.fieldName;
        const dateFieldName = res.find((r: any) => {
          return r.displayName === 'Date';
        })?.fieldName;

        setTimeout(() => {
          let allFilterObjs: any = {};

          if (this.filterMetricName && matricFieldName) {
            const obj = {
              [matricFieldName]: {
                filter: this.filterMetricName,
                filterType: 'text',
                type: 'contains',
              },
            };

            allFilterObjs = { ...allFilterObjs, ...obj };
          }

          if (this.filterValue && dateFieldName) {
            const obj = {
              [dateFieldName]: {
                dateFrom: this.filterValue,
                dateTo: null,
                filterType: 'date',
                type: 'equals',
              },
            };

            allFilterObjs = { ...allFilterObjs, ...obj };
          }

          if (allFilterObjs) {
            this.grid.setFilterModel(allFilterObjs);
          }
        }, 100);
      }

      // This must be maintained for recalculation in both pivot and non-pivot cases. Column spanning occurs in two scenarios:
      // 1. When the pivot mode is enabled, it is handled by `serverSidePivotResultFieldSeparator`.
      // 2. When pivot mode is off, the UI will format the header by grouping columns accordingly.

      this.originalHeaders = this.headers;
      this.isFirstTimeCall = true;
      // this.grid.setGridOption('serverSideDatasource', this.getGridDataFromAPI());

      // sidebar Tool Panels will only visible if pivoting/grouping is on
      // const isSidebarPanelVisible = this.headers.some(header => header.rowGroup || header.pivot);

      this.grid.setSideBarVisible(false);
    });
  }

  headerFormulate(header: any): any {
    const shouldHide = header.hidden || header.hide;
    const headerObj: ColumnDef | any = {
      id: header.id,
      field: header.fieldName ? `${header.fieldName.toLowerCase()}` : `${header.field.toLowerCase()}`,
      ...(header.groupRowsBy && {
        rowSpan: (params: any) => {
          return params.data[
            header.fieldName ? `${header.fieldName.toLowerCase()}` : `${header.field.toLowerCase()}`
          ] &&
            params.data[header.fieldName ? `${header.fieldName.toLowerCase()}` : `${header.field.toLowerCase()}`]
              .rowSpan > 0
            ? params.data[header.fieldName ? `${header.fieldName.toLowerCase()}` : `${header.field.toLowerCase()}`]
                .rowSpan
            : 1;
        },
        cellClassRules: {
          'cell-span': (params: any) => {
            return (
              params.data[header.fieldName ? `${header.fieldName.toLowerCase()}` : `${header.field.toLowerCase()}`] &&
              params.data[header.fieldName ? `${header.fieldName.toLowerCase()}` : `${header.field.toLowerCase()}`]
                .rowSpan > 0
            );
          },
          'cell-group': 'true',
        },
        cellClass: (params: any) => {
          return params.data[
            header.fieldName ? `${header.fieldName.toLowerCase()}` : `${header.field.toLowerCase()}`
          ] &&
            params.data[header.fieldName ? `${header.fieldName.toLowerCase()}` : `${header.field.toLowerCase()}`]
              .rowSpan > 0
            ? 'cell-span'
            : 'one-cell';
        },
      }),
      minWidth: header.minWidth,
      maxWidth: header.maxWidth,
      wrapText: header.textWrapping,
      autoHeight: header.groupRowsBy === false,
      // tooltipField: header.fieldName ? `${header.fieldName.toLowerCase()}` : `${header.field.toLowerCase()}`,
      ...(header.groupRowsBy && { tooltipValueGetter: (p: ITooltipParams) => p.value }),
      headerName: header.displayName ? header.displayName : header.headerName,
      sortable: header.sortable,
      ...(header.sortable && { sort: header.sortOrder ? header.sortDirection.toLowerCase() : header.sort }),
      // sort: header.sortOrder ? header.sortDirection.toLowerCase() : header.sort,
      initialSortIndex: header.sortOrder ? header.sortOrder : header.initialSortIndex,
      filter: header.filterable ? this.filterTypeService.getMeFilterType(header.fieldType) : header.filter,
      filterParams: header.filterable ? this.filterParams.getMeFilterParams(header.fieldType) : header.filterParams,
      cellRenderer: (rowData: any) => {
        return this.cellRendererService.reportCellRenderer(rowData, 'REPORT');
      },
      enableSpan: header.groupRowsBy,
      enableRowGroup: false, // header.enableRowGroup,
      rowGroup: false, // header.groupRowsBy && header.enablePivot,
      hide: shouldHide,
      enableValue: header.enableValue,
      aggFunc: header.aggFunction,
      dataTypeFormatId: header.dataTypeFormatId,
      enablePivot: false, // header.enablePivot,
      pivot: false, // header.groupColumnsBy,
    };

    return headerObj;
  }

  onSortChange(): void {
    if (!this.reportData.length) {
      this.paginationService.setLbLastRowOnPage(this.grid, this.gridElementRef, true);
    }
  }

  onFilterModified(e: any): void {
    this.filterTextWhitespacesService.textWhitespaceTrim(e, this.grid);
  }

  getMeSorted(dirtyData: any): any {
    if (dirtyData.length > 0) {
      const sort: any = [];

      for (let i = 0; i <= dirtyData.length - 1; i -= -1) {
        sort.push(`${dirtyData[i].colId},${dirtyData[i].sort}`);
      }

      return sort;
    }

    return '';
  }

  findActualValueForGroupKey(formattedValue: any, actualValueKey: string): any {
    // Use Array.prototype.find to search through the reportData and find the actual value
    const rowInfo = this.actualValuesMap.find((row: any) => row[actualValueKey] !== undefined);

    return rowInfo ? rowInfo[actualValueKey] : undefined;
  }

  transformGroupKeys(groupKeys: any[], rowGroupCols: any[]): any[] {
    return groupKeys.map((key: any, index: number) => {
      const { field } = rowGroupCols[index];
      const actualValueKey = `${field}Value`;
      // Find the corresponding row data entry that contains the actual value
      const actualValue = this.findActualValueForGroupKey(key, actualValueKey);

      return actualValue !== undefined ? actualValue : key;
    });
  }

  transformRowGroupInfo(rowGroupInfo: any): any {
    // Create a mapping from fieldName to an object containing id, fieldName, and aggFunc from headers
    const fieldToIdMap = this.headers.reduce((accumulator: any, header: any) => {
      accumulator[header.field] = {
        id: header.id,
        fieldName: header.field,
        aggFunction: header.aggFunc,
        formatId: header.dataTypeFormatId,
      };

      return accumulator; // Return the accumulator object for the next iteration
    }, {});

    // Transform groupKeys by mapping each key to its corresponding actual value
    const groupKeys = this.transformGroupKeys(rowGroupInfo.groupKeys, rowGroupInfo.rowGroupCols);

    // Transform rowGroupCols by mapping each column to its corresponding id and fieldName
    const groupByRow = rowGroupInfo.rowGroupCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      // If mapping exists, replace the id and fieldName, otherwise, return the column unchanged
      return mappedField
        ? { ...col, id: mappedField.id, fieldName: mappedField.fieldName, formatId: mappedField.formatId }
        : col;
    });

    // Transform valueCols by mapping each column to its corresponding id and fieldName, and override aggFunc if available
    const valueCols = rowGroupInfo.valueCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      return mappedField
        ? {
            ...col,
            id: mappedField.id,
            fieldName: mappedField.fieldName,
            aggFunction: mappedField.aggFunc || col.aggFunc,
            formatId: mappedField.formatId,
          }
        : col;
    });

    // Transform pivotCols
    const pivotColumns = rowGroupInfo.pivotCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      return mappedField
        ? { ...col, id: mappedField.id, fieldName: mappedField.fieldName, formatId: mappedField.formatId }
        : col;
    });

    // Transform sortModel
    const sortModel = rowGroupInfo.sortModel.map((sort: any) => {
      const mappedField = fieldToIdMap[sort.colId.toLowerCase()];

      return mappedField ? { ...sort, colId: mappedField.fieldName } : sort;
    });

    // Creating a new object with transformed properties
    return {
      ...rowGroupInfo,
      groupKeys,
      groupByRow,
      valueCols,
      pivotColumns,
      sortModel,
    };
  }

  getMeFiltered(filterData: any, rowGroupInfo: any): any {
    const sqlQueries: any = [];

    Object.keys(filterData).forEach(key => {
      const filterItem = filterData[key];
      const conditions: any = [];

      const appliedFilterData: any = {
        fieldName: key,
        filterType: filterItem.filterType,
      };

      if (filterItem.operator) {
        appliedFilterData.operator = filterItem.operator;

        filterItem.conditions.forEach((item: any) => {
          const filterVal: any = {
            type: item.type,
            start: item.filterType === 'date' ? item.dateFrom : item.filter,
          };

          if (item.type === 'inRange') {
            filterVal.end = item.filterType === 'date' ? item.dateTo : item.filterTo;
          }
          conditions.push(filterVal);
        });
      } else {
        const filterVal: any = {
          type: filterItem.type,
          start: filterItem.filterType === 'date' ? filterItem.dateFrom : filterItem.filter,
        };

        if (filterItem.type === 'inRange') {
          filterVal.end = filterItem.filterType === 'date' ? filterItem.dateTo : filterItem.filterTo;
        }
        conditions.push(filterVal);
      }

      appliedFilterData.conditions = conditions;

      sqlQueries.push(appliedFilterData);
    });

    this.haveFilters =
      (this.routeFilters && this.routeFilters.length > 0) || Object.values(this.grid.getFilterModel()).length > 0;

    const filterConditions = [...sqlQueries]; // Start with search filters
    const payload: any = filterConditions.length ? { filterConditions } : {}; // Preparing the payload for search filters
    const agParamsRequestData: any = this.transformRowGroupInfo(rowGroupInfo); // Transform row group info for additional filters

    //  Merge filters, ensuring search filters take precedence
    if (agParamsRequestData.filterConditions?.length) {
      // Append only additional filters that are not already in search filters
      const additionalFilters = agParamsRequestData.filterConditions.filter(
        (newFilter: any) =>
          !filterConditions.some((existingFilter: any) => existingFilter.fieldName === newFilter.fieldName)
      );

      filterConditions.push(...additionalFilters);
    }

    // Merge the transformed data with search filters
    const mergedPayload = {
      ...agParamsRequestData,
      ...payload,
      filterConditions, // Use the merged filterConditions
    };

    //  Remove unnecessary keys
    delete mergedPayload.startRow;
    delete mergedPayload.endRow;

    return mergedPayload;
  }

  onCellClick(event: any): void {
    if (event.data.attributes.length) {
      event.data.attributes.forEach((property: any) => {
        if (event.column.colId === property.field) {
          if (property.name === 'Drill to Upload' && this.reportPermissionService.hasUploadPermission()) {
            this.isUpload = !this.isUpload;
            this.reportService.getUploadViewData({ data: event.data, property });

            //  to remove z index
            setTimeout(() => {
              const res: any = document.getElementsByClassName('p-component-overlay');

              if (res && res.length > 0) {
                res[2].style.zIndex = '';
              }
            }, 500);
          }

          // default webform will open if multiple/single webforms will be availble on a single cell
          if (property.name === 'Drill To Webform Add' || property.name === 'Drill To Webform Edit') {
            this.webformRightsidebar(event.data, property);
          }
        }
      });
    }
  }

  getGridDataFromAPI(): any {
    return {
      getRows: (agParams: any) => {
        const params: any = {
          offset: agParams.request.startRow,
          limit: agParams.request.endRow - agParams.request.startRow,
          sort: this.getMeSorted(agParams.request.sortModel),
          rowSpan: true,
        };

        // updating cache to be in sync with page size
        this.grid.setGridOption('cacheBlockSize', this.grid.paginationGetPageSize());

        this.reportService
          .getReportData(this.reportId, this.getMeFiltered(agParams.request.filterModel, agParams.request), params)
          .subscribe((res: any) => {
            this.reportData = res.elements;
            if (!res.elements.length) {
              this.grid.showNoRowsOverlay();
            } else {
              this.grid.hideOverlay();
            }
            const tableData: any = res.elements.map((row: any) => {
              const rowDataEntry: any = {};
              let attributes: any = [];

              const hiddenHeaders = this.headers
                .filter(head => {
                  return head.hide;
                })
                .map(m => String(m.field).toLowerCase());

              if (this.isFirstTimeCall) {
                // Group headers logic
                if (res && res.reportColumnRoot && res.reportColumnRoot.length > 0 && !this.grid.isPivotMode()) {
                  const groupedData: GroupedData = res.reportColumnRoot.reduce((acc: GroupedData, item: any) => {
                    if (item.groupName) {
                      const { groupName } = item;

                      if (res.elements[0].reportColumnData && res.elements[0].reportColumnData.length > 0) {
                        const formatedData = res.elements[0].reportColumnData.find((data: any) => {
                          return (
                            data.displayName &&
                            data.displayName.substring(0, data.displayName.indexOf(':')) === item.displayName &&
                            String(data.displayName.split(':')[1]).toLowerCase() ===
                              String(item.groupName).toLowerCase()
                          );
                        });

                        if (formatedData) {
                          // eslint-disable-next-line no-param-reassign
                          item.fieldName = formatedData.fieldName;
                        }
                      }
                      let header;

                      if (
                        hiddenHeaders &&
                        hiddenHeaders.length > 0 &&
                        item.groupName &&
                        !hiddenHeaders.includes(String(item.groupName).toLowerCase())
                      ) {
                        if (
                          this.headers.find((h: any) => {
                            return h.headerName.toLowerCase() === String(item.groupName).toLowerCase();
                          })
                        ) {
                          const f = this.headers.find((h: any) => {
                            return h.headerName.toLowerCase() === String(item.groupName).toLowerCase();
                          });

                          if (f.hide && f.hide === true) {
                            // eslint-disable-next-line no-param-reassign
                            item.hide = true;
                          }
                        }

                        header = this.headerFormulate(item);
                      }

                      if (!acc[groupName]) {
                        acc[groupName] = [];
                      }
                      if (header) {
                        acc[groupName].push(header);
                      }
                    }

                    return acc;
                  }, {} as GroupedData);

                  // Combine headers from API
                  this.headers = this.headers.reduce((acc: any, header: any) => {
                    if (groupedData[header.headerName]) {
                      acc.push({
                        ...header,
                        children: groupedData[header.headerName],
                      });
                    } else {
                      acc.push(header);
                    }

                    return acc;
                  }, []);
                } else {
                  // Restore original headers if no grouping
                  this.headers = this.originalHeaders;
                }
                this.isFirstTimeCall = false;
              }

              // Construct row data
              row.reportColumnData.forEach((colData: any) => {
                const fieldName = this.grid.isPivotMode() ? colData.fieldName : colData.fieldName.toLowerCase();
                const { displayName } = colData;

                // Dynamically bind fields to rowDataEntry
                rowDataEntry[fieldName] = colData.formattedValue; // Store formatted value

                rowDataEntry[fieldName] = colData.formattedValue; // Store formatted value
                rowDataEntry[`${fieldName}Value`] = colData.value; // Store actual value

                // Dynamically bind column display name to rowDataEntry
                rowDataEntry[displayName] = colData.formattedValue || '';
                rowDataEntry[`${displayName}Value`] = colData.value || '';

                // Pass field identifier in attributes
                if (colData.reportAttributeDtos) {
                  const newAttr = colData.reportAttributeDtos?.map((v: any) => ({
                    ...v,
                    field: colData.fieldName.toLowerCase(),
                  }));

                  attributes = [...attributes, ...newAttr];
                }
                rowDataEntry.attributes = attributes;
              });

              return rowDataEntry;
            });

            this.headers.forEach(_header => {
              // eslint-disable-next-line no-param-reassign
              _header.field = _header.field.replace('.value', '');
            });

            const headers = this.headers.filter((f: any) => {
              return !f.hide;
            });
            const makedData = this.rowSpanHelperService.createData(headers, tableData);

            tableData.forEach((make: any, index: number) => {
              // eslint-disable-next-line no-param-reassign
              make = Object.assign(make, makedData[index]);
            });

            this.headers.forEach((header: any) => {
              if (header && header.field && !header.hide) {
                // eslint-disable-next-line no-param-reassign
                header.field = `${header.field}.value`;
              }
            });

            /**
             * Pivoting will be adjusted in success
             */
            agParams.success({
              rowData: tableData,
              rowCount: res.totalElements,
              pivotResultFields: this.grid.isPivotMode()
                ? res.reportColumnRoot && res.reportColumnRoot.map((rtRoot: any) => rtRoot.fieldName)
                : this.grid.refreshHeader(),
            });

            // Auto-resize all columns by default
            agParams.columnApi.autoSizeAllColumns();
            this.routeFilters = []; // Resetting as the search navigation has been completed
            this.searchService.setFilters([]);

            // Workaround to show the actual number of rows for a given page
            this.paginationService.setLbLastRowOnPage(this.grid, this.gridElementRef);
          });
      },
    };
  }

  onGridReady(event: any): void {
    this.grid = event.api;
    if (event.api) {
      event.api.sizeColumnsToFit();
      this.gridReady$.next(true); // Signal grid is ready
      this.gridReady$.complete(); // Complete the observable
    }
    this.grid.setGridOption('serverSideDatasource', this.getGridDataFromAPI());
  }

  private getMainMenuItems(): [] {
    const customResetCol: any = [
      'pinSubMenu',
      'separator',
      {
        name: 'Reset Columns',
        action: () => {
          this.grid.autoSizeAllColumns();
          this.grid.resetColumnState();
        },
      },
    ];

    return customResetCol;
  }

  private getContextMenuItems(params: any): [] {
    const rowData = params.node.data;
    const menuItems: any = [];

    if (rowData.attributes.length) {
      rowData.attributes.forEach((property: any) => {
        if (params.column.colId === property.field) {
          if (property.name === 'Drill To Webform Add' || property.name === 'Drill To Webform Edit') {
            menuItems.push(this.getMenuItems(rowData, property));
          }
        }
      });
    }

    return menuItems;
  }

  private getMenuItems(event: any, formProperty: any): string | MenuItemDef {
    const editCell = 'Drill To Webform Edit';

    const result: string | MenuItemDef = {
      name: formProperty.name === editCell ? 'Edit Webform' : 'Add WebForm',
      icon:
        formProperty.name === editCell
          ? '<i class="pi pi-file-edit p-1 align-middlee"></i>'
          : '<i class="pi pi-plus-circle p-1 align-middlee"></i>',
      cssClasses: formProperty.name === editCell ? ['fw-bold', 'cursor-pointer'] : ['fw-bold', 'cursor-pointer'],
      action: () => {
        this.webformRightsidebar(event, formProperty);
      },
    };

    return result;
  }

  private webformRightsidebar(params: any, property: any): void {
    this.isAddEnabled = true;
    this.reportService.getUploadViewData({ data: params, property, header: 'Name of report' }); // need to add name of reports
  }

  private onColumnPivotModeChanged(event: any): void {
    const isPivotModeEnabled = event.api.isPivotMode();

    if (isPivotModeEnabled) {
      const isPivotFromHead: boolean = this.headers.some((header: any) => header.pivot === true);

      this.grid.setGridOption('pivotMode', isPivotFromHead);
    }
  }
}
