import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';

import { ValidationService } from '@app/core/services/validation.service';
import { ConnectorParametersComponent } from '@app/shared/components/connector-parameters/connector-parameters.component';
import { UdfComponent } from '@app/shared/components/udf/udf.component';
import { ErrorBlockService } from '@app/shared/services/error-block.service';
import { CatalogService } from '@app/slm/services/catalog.service';
import { MetricPermissionService } from '@app/slm/services/metric-permission.service';
import { UdfService } from '@app/slm/services/udf.service';

@Component({
  selector: 'app-edit-metric-catalog',
  templateUrl: './edit-metric-catalog.component.html',
  styleUrls: ['./edit-metric-catalog.component.scss'],
})
export class EditMetricCatalogComponent implements OnInit {
  @Input() viewMetricData: any = null;

  @Output() closeClicked = new EventEmitter<boolean>();

  @ViewChild('op', { static: false }) overlayPanel: any;

  @ViewChild('paginationReset') paginationReset!: Table;

  @ViewChild(UdfComponent) udfComponent!: UdfComponent;

  @ViewChildren(UdfComponent) childComponents!: QueryList<UdfComponent>;

  @ViewChild(ConnectorParametersComponent) connectorParametersComponent!: ConnectorParametersComponent;

  viewData: any;

  minStartDate = new Date('01-01-1901');

  maxEndDate = new Date('12-31-9999');

  minEndDate!: Date;

  editMetricForm!: FormGroup;

  errorMsgList: any = [];

  viewVersionList: any = [];

  selectedVersion: any;

  filteredVersion: any = [];

  statusList: any = [
    { label: 'Draft', class: 'warning-chip', icon: 'assets/icons/orange.svg' },
    { label: 'Published', class: 'success-chip', icon: 'assets/icons/green.svg' },
    { label: 'Revoked', class: 'danger-chip', icon: 'assets/icons/red.svg' },
  ];

  selectedStatusFilter: any = { Draft: false, Published: false, Revoked: false };

  metricUDFData: any;

  metricVersionObjectFields: any;

  UDFVersionList: any;

  viewUdfList: any;

  viewMetricParamList: any = [];

  isEditMetricUDF!: boolean;

  metricGeneralPayload: any = [];

  connectorParamPayload: any = [];

  metricUpdatedDetails: any;

  constructor(
    private catalogService: CatalogService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private messageService: MessageService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    public metricPermission: MetricPermissionService,
    private udfService: UdfService,
    private errorBlockService: ErrorBlockService
  ) {}

  ngOnInit(): void {
    this.editMetricForm = this.formBuilder.group({
      id: ['', [Validators.maxLength(512)]],
      name: ['', [ValidationService.requiredValidator, Validators.maxLength(250)]],
      versionName: ['', [Validators.maxLength(250)]],
      target: [],
      expected: [],
      startDate: [''],
      endDate: [''],
      status: [''],
      versionStatement: [null, [Validators.maxLength(250)]],
      statusFilter: new FormArray([]),
    });

    this.addCheckboxes();

    this.catalogService.isSelectedVersion$.subscribe((res: any) => {
      if (res) {
        this.viewData = res.viewData;
        this.viewUdfList = this.viewData.objectFieldData;
        this.viewMetricParamList = res.metricCatalogParamValues;
        this.viewVersionList = this.viewData.metricCatalogVersions;
        this.patchValue(res.selectedVersion);
        this.selectedVersion = res.selectedVersion;
        this.filteredVersion = this.viewVersionList;
        this.isEditMetricUDF = res.isEditUDFEnabled;
      }
    });

    this.editMetricForm.get('expected')?.disable();
  }

  patchValue(data: any): void {
    this.onSelectStartDate(new Date(data.startDate));

    this.editMetricForm.patchValue({
      id: this.viewData.metricNo,
      name: this.viewData.name,
      versionName: data.versionName,
      target: data.target,
      expected: data.expected,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
      status: data.status,
      versionStatement: data.statement,
    });
    if (data.status !== 'Draft') {
      this.editMetricForm.disable();
      if (data.status === 'Published') {
        this.editMetricForm.get('versionStatement')?.enable();
      }
    } else {
      this.editMetricForm.enable();
    }
    // create version UDF here as data will be patched as per version
    if (data.objectFieldData && data.objectFieldData.length > 0) {
      this.UDFVersionList = data.objectFieldData || [];
      this.udfService.getUDFType(data);
      // this.cdr.detectChanges(); // Trigger change detection
    }
    this.editMetricForm.get('statusFilter')?.enable();
  }

  applyStatusFilter(event: any, field: string): void {
    Object.keys(this.selectedStatusFilter).forEach(key => {
      if (key === field) {
        this.selectedStatusFilter[key] = event.checked;
      }
    });

    // filter the versions from the list
    const filterList: any = [];

    this.paginationReset?.reset();

    this.viewVersionList.forEach((status: any) => {
      if (this.selectedStatusFilter[status.status]) {
        filterList.push(status);
      }
    });

    // check if no filter selected show initial list
    const noFilterSelected = Object.values(this.selectedStatusFilter).every(v => !v);

    if (noFilterSelected) {
      this.filteredVersion = this.viewVersionList;
    } else {
      this.filteredVersion = filterList;
    }
  }

  onClosePanel(event: any): void {
    this.selectedVersion = event.data;
    this.UDFVersionList = [];
    this.patchValue(this.selectedVersion);

    this.overlayPanel.hide();
  }

  onSelectStartDate(event: any): void {
    this.minEndDate = new Date();
    this.minEndDate = new Date(event.getFullYear(), event.getMonth(), event.getDate() + 1);
    this.editMetricForm.get('endDate')?.reset();
  }

  closeViewMetric(): void {
    this.viewData = [];
    this.viewVersionList = [];
    this.errorBlockService.clearErrors();
    this.minEndDate = new Date();
    this.editMetricForm.reset();
    this.catalogService.getEditVersionInfo(false);
  }

  onParameterFormSubmit(paramFormData: any): void {
    // Check if either 'metricParamsValue' exist and have length > 0
    if (paramFormData.metricParamValues && paramFormData.metricParamValues.length > 0) {
      this.connectorParamPayload = paramFormData.metricParamValues.filter((obj: any) => {
        const hasValue =
          Object.prototype.hasOwnProperty.call(obj, 'value') && obj.value !== null && obj.value !== undefined;

        const hasValueSetRowId =
          Object.prototype.hasOwnProperty.call(obj, 'valueSetRowId') &&
          obj.valueSetRowId !== null &&
          obj.valueSetRowId !== undefined;

        const hasHierarchyNodeId =
          Object.prototype.hasOwnProperty.call(obj, 'hierarchyNodeId') &&
          obj.hierarchyNodeId !== null &&
          obj.hierarchyNodeId !== undefined;

        const hasCalendarGroupId =
          Object.prototype.hasOwnProperty.call(obj, 'calendarId') &&
          obj.calendarId !== null &&
          obj.calendarId !== undefined;

        return hasValue || hasValueSetRowId || hasHierarchyNodeId || hasCalendarGroupId;
      });
    }
  }

  /** UDF */
  onFormDataChanged(formData: any): void {
    /**
     * Check if the field has either 'value' or 'valueSetKey'
     * and ensure that null/undefined are properly handled.
     */
    if (formData.metricObjectFields && formData.metricObjectFields.length > 0) {
      this.metricUDFData = formData.metricObjectFields.filter((obj: any) => {
        // Check for value existence: value may be number, string, or present but null/undefined
        const hasValue =
          Object.prototype.hasOwnProperty.call(obj, 'value') && obj.value !== null && obj.value !== undefined;
        const hasValueSetKey =
          Object.prototype.hasOwnProperty.call(obj, 'valueSetKey') &&
          obj.valueSetKey !== null &&
          obj.valueSetKey !== undefined;

        return hasValue || hasValueSetKey;
      });
    }

    if (formData.metricVersionObjectFields && formData.metricVersionObjectFields.length > 0) {
      this.metricVersionObjectFields = formData.metricVersionObjectFields.filter((obj: any) => {
        // Check for value existence: value may be number, string, or present but null/undefined
        const hasValue =
          Object.prototype.hasOwnProperty.call(obj, 'value') && obj.value !== null && obj.value !== undefined;
        const hasValueSetKey =
          Object.prototype.hasOwnProperty.call(obj, 'valueSetKey') &&
          obj.valueSetKey !== null &&
          obj.valueSetKey !== undefined;

        return hasValue || hasValueSetKey;
      });
    }
  }

  saveUpdatedCatalog(): void {
    this.errorBlockService.clearErrors();
    if (
      this.editMetricForm.invalid ||
      (this.udfComponent?.dynamicForm?.invalid ?? false) ||
      this.childComponents.some(child => child.dynamicForm.invalid) ||
      (this.connectorParametersComponent?.dynamicForm?.invalid ?? false)
    ) {
      this.editMetricForm.markAllAsTouched();
      this.childComponents.forEach(child => {
        child.dynamicForm.markAllAsTouched();
      });
    } else {
      const startDate = this.datePipe.transform(this.editMetricForm.get('startDate')?.value, 'yyyy-MM-dd');
      const endDate = this.datePipe.transform(this.editMetricForm.get('endDate')?.value, 'yyyy-MM-dd');
      const versionName = this.editMetricForm.get('versionName')?.value;
      const versionStatement = this.editMetricForm.get('versionStatement')?.value;

      const payload = {
        metricNo: this.editMetricForm.get('id')?.value,
        name: this.editMetricForm.get('name')?.value,
        versionName: versionName.versionName ? versionName.versionName : versionName,
        target: this.editMetricForm.get('target')?.value,
        expected: this.editMetricForm.get('expected')?.value,
        startDate: startDate ? new Date(startDate).toISOString() : '',
        endDate: endDate ? new Date(endDate).toISOString() : '',
        versionStatement: versionStatement || null,
        description:
          this.viewMetricData.description && this.viewMetricData.description !== 'N/A'
            ? this.viewMetricData.description
            : null,
        metricConnectorId:
          this.viewMetricData.metricConnectorId && this.viewMetricData.metricConnectorId !== 'N/A'
            ? this.viewMetricData.metricConnectorId
            : null,
        businessOfferingId:
          this.viewMetricData.businessOfferingId && this.viewMetricData.businessOfferingId !== 'N/A'
            ? this.viewMetricData.businessOfferingId
            : null,
        statement:
          this.viewMetricData.statement && this.viewMetricData.statement !== 'N/A'
            ? this.viewMetricData.statement
            : null,
        conditionId:
          this.viewMetricData.conditionId && this.viewMetricData.conditionId !== 'N/A'
            ? this.viewMetricData.conditionId
            : null,
        targetDataTypeId:
          this.viewMetricData.targetDataTypeId && this.viewMetricData.targetDataTypeId !== 'N/A'
            ? this.viewMetricData.targetDataTypeId
            : null,
        formatId:
          this.viewMetricData.targetFormatId && this.viewMetricData.targetFormatId !== 'N/A'
            ? this.viewMetricData.targetFormatId
            : null,
        periodId:
          this.viewMetricData.periodId && this.viewMetricData.periodId !== 'N/A' ? this.viewMetricData.periodId : null,
        id: this.viewData.id,
        ...(this.metricUDFData && this.metricUDFData.length > 0 ? { objectFieldData: this.metricUDFData } : {}),
        ...(this.viewMetricParamList && this.viewMetricParamList.length > 0
          ? { metricCatalogParamValues: this.connectorParamPayload }
          : {}),
      };

      this.catalogService.editCatalogDetails(payload).subscribe(
        (res: any) => {
          if (res) {
            this.catalogService.getCreatedMetricData({ res });

            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
              detail: this.translateService.instant('MODULES.CREATE_METRIC.VERSION_EDIT_SUCCESS', {
                metric: res.name,
                version: res.metricCatalogVersions[0].versionName,
              }),
            });

            this.closeViewMetric();
            this.catalogService.toogleViewSidebar(false);
          }
        },
        (err: any) => {
          if (err && Array.isArray(err)) {
            err.forEach((msg: any) => {
              this.errorBlockService.addError(msg.message);
            });
          }
        }
      );
    }
  }

  // Publish Metric and Metric Versions
  publishVersion(): void {
    this.errorBlockService.clearErrors();

    if (
      this.editMetricForm.invalid ||
      (this.udfComponent?.dynamicForm?.invalid ?? false) ||
      this.childComponents.some(child => child.dynamicForm.invalid) ||
      (this.connectorParametersComponent?.dynamicForm?.invalid ?? false)
    ) {
      this.editMetricForm.markAllAsTouched();
      this.childComponents.forEach(child => {
        child.dynamicForm.markAllAsTouched();
      });
    } else {
      const startDate = this.datePipe.transform(this.editMetricForm.get('startDate')?.value, 'yyyy-MM-dd');
      const endDate = this.datePipe.transform(this.editMetricForm.get('endDate')?.value, 'yyyy-MM-dd');
      const versionName = this.editMetricForm.get('versionName')?.value;
      const versionStatement = this.editMetricForm.get('versionStatement')?.value;

      const payload = {
        metricNo: this.editMetricForm.get('id')?.value,
        name: this.editMetricForm.get('name')?.value,
        versionName: versionName.versionName ? versionName.versionName : versionName,
        target: this.editMetricForm.get('target')?.value,
        expected: this.editMetricForm.get('expected')?.value,
        startDate: startDate ? new Date(startDate).toISOString() : '',
        endDate: endDate ? new Date(endDate).toISOString() : '',
        versionStatement: versionStatement || null,
        description:
          this.viewMetricData.description && this.viewMetricData.description !== 'N/A'
            ? this.viewMetricData.description
            : null,
        metricConnectorId:
          this.viewMetricData.metricConnectorId && this.viewMetricData.metricConnectorId !== 'N/A'
            ? this.viewMetricData.metricConnectorId
            : null,
        businessOfferingId:
          this.viewMetricData.businessOfferingId && this.viewMetricData.businessOfferingId !== 'N/A'
            ? this.viewMetricData.businessOfferingId
            : null,
        statement:
          this.viewMetricData.statement && this.viewMetricData.statement !== 'N/A'
            ? this.viewMetricData.statement
            : null,
        conditionId:
          this.viewMetricData.conditionId && this.viewMetricData.conditionId !== 'N/A'
            ? this.viewMetricData.conditionId
            : null,
        targetDataTypeId:
          this.viewMetricData.targetDataTypeId && this.viewMetricData.targetDataTypeId !== 'N/A'
            ? this.viewMetricData.targetDataTypeId
            : null,
        formatId:
          this.viewMetricData.targetFormatId && this.viewMetricData.targetFormatId !== 'N/A'
            ? this.viewMetricData.targetFormatId
            : null,
        periodId:
          this.viewMetricData.periodId && this.viewMetricData.periodId !== 'N/A' ? this.viewMetricData.periodId : null,
        id: this.viewData.id,
        ...(this.metricUDFData && this.metricUDFData.length > 0 ? { objectFieldData: this.metricUDFData } : {}),
        ...(this.viewMetricParamList && this.viewMetricParamList.length > 0
          ? { metricCatalogParamValues: this.connectorParamPayload }
          : {}),
      };

      this.catalogService.publishEditCatalog(payload).subscribe(
        (res: any) => {
          if (res) {
            this.catalogService.getCreatedMetricData({ res });

            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
              detail: this.translateService.instant('MODULES.CREATE_METRIC.VERSION_PUBLISH_EDIT_SUCCESS', {
                metric: res.name,
                version: res.metricCatalogVersions[0].versionName,
              }),
            });
            this.closeViewMetric();
            this.catalogService.toogleViewSidebar(false);
          }
        },
        (err: any) => {
          if (err && Array.isArray(err)) {
            err.forEach((msg: any) => {
              this.errorBlockService.addError(msg.message);
            });
          }
        }
      );
    }
  }

  cofirmPublish(event: Event): void {
    this.errorBlockService.clearErrors();

    if (!this.editMetricForm.get('startDate')?.value || !this.editMetricForm.get('endDate')?.value) {
      const msg = {
        message: this.translateService.instant('MODULES.VIEW_METRICS.ERROR'),
        fieldName: 'Date',
      };

      this.errorBlockService.addError(msg.message);

      this.editMetricForm.markAllAsTouched();
      this.udfComponent?.dynamicForm?.markAllAsTouched();
    } else if (
      this.editMetricForm.invalid ||
      (this.udfComponent?.dynamicForm?.invalid ?? false) ||
      this.childComponents.some(child => child.dynamicForm.invalid) ||
      (this.connectorParametersComponent?.dynamicForm?.invalid ?? false)
    ) {
      this.editMetricForm.markAllAsTouched();
      this.childComponents.forEach(child => {
        child.dynamicForm.markAllAsTouched();
      });
    } else {
      this.confirmationService.confirm({
        target: event.target ? event.target : undefined,
        icon: 'pi pi-exclamation-triangle',
        message: this.translateService.instant('MODULES.CONTRACTS.CONFIRM_PUBLISH'),
        acceptLabel: this.translateService.instant('MODULES.CONTRACTS.YES'),
        rejectLabel: this.translateService.instant('MODULES.CONTRACTS.NO'),
        rejectButtonStyleClass: 'p-button-outlined',
        accept: () => {
          this.publishVersion();
        },
      });
    }
  }

  // revokeVersion(metricId: number): void {
  //   this.errorBlockService.clearErrors();

  //   const versionId = this.editMetricForm.get('versionName')?.value?.versionId
  //     ? this.editMetricForm.get('versionName')?.value?.versionId
  //     : this.selectedVersion.versionId;

  //   const versionName = this.editMetricForm.get('versionName')?.value;
  //   const startDate = this.datePipe.transform(this.editMetricForm.get('startDate')?.value, 'yyyy-MM-dd');
  //   const endDate = this.datePipe.transform(this.editMetricForm.get('endDate')?.value, 'yyyy-MM-dd');
  //   const versionStatement = this.editMetricForm.get('versionStatement')?.value;

  //   const basePayload = {
  //     versionName: versionName.versionName ? versionName.versionName : versionName,
  //     target: this.editMetricForm.get('target')?.value,
  //     expected: this.editMetricForm.get('expected')?.value,
  //     startDate: startDate ? new Date(startDate).toISOString() : '',
  //     endDate: endDate ? new Date(endDate).toISOString() : '',
  //     versionStatement: versionStatement || null,
  //   };
  //   const payload = {
  //     ...basePayload,
  //     ...(this.metricVersionObjectFields && this.metricVersionObjectFields.length > 0
  //       ? { objectFields: this.metricVersionObjectFields }
  //       : {}),
  //   };

  //   this.metricService.revokeVersion(metricId, versionId, payload).subscribe(
  //     (res: any) => {
  //       if (res) {
  //         this.catalogService.getCreatedMetricData({ res });
  //         this.messageService.add({
  //           severity: 'success',
  //           summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
  //           detail: this.translateService.instant('MODULES.CREATE_METRIC.VERSION_REVOKE', {
  //             metric: this.viewData.metricName,
  //             version: res.versionName,
  //           }),
  //         });
  //         this.closeViewMetric();
  //         this.metricService.toogleSidebar(false);
  //       }
  //     },
  //     (err: any) => {
  //       if (err && Array.isArray(err)) {
  //         err.forEach((msg: any) => {
  //           this.errorBlockService.addError(msg.message);
  //         });
  //       }
  //     }
  //   );
  // }

  // cofirmRevoke(event: Event): void {
  //   this.confirmationService.confirm({
  //     target: event.target ? event.target : undefined,
  //     icon: 'pi pi-exclamation-triangle',
  //     message: this.translateService.instant('MODULES.CONTRACTS.CONFIRM_REVOKE'),
  //     acceptLabel: this.translateService.instant('MODULES.CONTRACTS.YES'),
  //     rejectLabel: this.translateService.instant('MODULES.CONTRACTS.NO'),
  //     rejectButtonStyleClass: 'p-button-outlined',
  //     accept: () => {
  //       this.revokeVersion(this.viewData.id);
  //     },
  //   });
  // }

  // get status Icon by metric status
  getStatusIcon(): string {
    const statusIcon = this.statusList.find((item: any) => item.label === this.viewData?.status);

    return statusIcon.icon;
  }

  getTagetValue(): void {
    if (this.editMetricForm.get('target')?.value) {
      this.editMetricForm.get('expected')?.enable();
    } else {
      this.editMetricForm.get('expected')?.disable();
      this.editMetricForm.patchValue({ expected: null });
    }
  }

  private addCheckboxes(): void {
    this.statusList.map(() => {
      const control = new FormControl();

      return (this.editMetricForm.get('statusFilter') as FormArray).push(control);
    });
  }
}
