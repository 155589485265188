import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { environment } from '../../../environments/environment';

const routes = {
  catalogTree: `${environment.SLMUrl}/hierarchies/hierarchyOfBusinessServiceForMetricCatalog`,
  catalogTable: `${environment.SLMUrl}/metricCatalog/businessOfferings/`,
  catalogDetails: `${environment.SLMUrl}/metricCatalog/details/`,
  metricConnectorDetails: `${environment.SLMUrl}/metricconnectors/metricCatalogs/`,
  metricConnectors: `${environment.SLMUrl}/metricconnectors/search`,
  contracts: `${environment.SLMUrl}/contracts/search`,
  metricPeriod: `${environment.SLMUrl}/metricperiods`,
  metricCondition: `${environment.SLMUrl}/metricconditions`,
  metricTargetFormat: `${environment.SLMUrl}/metrictargetdatatypes`,
  metricTargetSubFormat: `${environment.SLMUrl}/metrictargetformats`,
  metricCatalog: `${environment.SLMUrl}/metricCatalog`,
  metricCatalogHeaders: `${environment.SLMUrl}/reports/Catalog%20Metrics/header`,
  metricCatalogData: `${environment.SLMUrl}/reports/Catalog%20Metrics/data`,
  udfRoute: `${environment.SLMUrl}/object-fields`,
  metricDetails: `${environment.SLMUrl}/metricCatalog/details/`,
  metricConnectorsParams: `${environment.SLMUrl}/metricconnectors`,
};

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  viewData = new BehaviorSubject<any>('');

  selectedNodeData = new BehaviorSubject<any>('');

  createdCatalogMetric = new Subject<any>();

  isViewCatalogSidebar = new Subject<any>();

  editVersionInfo = new Subject<any>();

  addVersionInfo = new Subject<any>();

  isViewOpen$ = this.isViewCatalogSidebar.asObservable();

  selectedVersion = new BehaviorSubject<any>('');

  isSelectedVersion$ = this.selectedVersion.asObservable();

  constructor(private httpClient: HttpClient) {}

  toogleViewSidebar(data: any): void {
    this.isViewCatalogSidebar.next(data);
  }

  getViewData(data: any): void {
    this.viewData.next(data);
  }

  getSelectedNodeData(data: any): void {
    this.selectedNodeData.next(data);
  }

  getCreatedMetricData(data: any): void {
    this.createdCatalogMetric.next(data);
  }

  getSelectedVersion(data: any): void {
    this.selectedVersion.next(data);
  }

  getEditVersionInfo(data: boolean): void {
    this.editVersionInfo.next(data);
  }

  getAddVersionInfo(data: boolean): void {
    this.addVersionInfo.next(data);
  }

  getMetricTreeData(): any {
    return this.httpClient.get(routes.catalogTree);
  }

  getMetricsTableData(businessId: string, params: any = {}): any {
    return this.httpClient.get(`${routes.catalogTable + businessId}`, { params });
  }

  getViewCatalog(id: number): any {
    return this.httpClient.get(`${routes.catalogDetails}${id}`);
  }

  getMetricCatalog(id: number): any {
    return this.httpClient.get(`${routes.metricConnectorDetails}${id}`);
  }

  getMetricConnectors(params: any = {}): any {
    return this.httpClient.get(routes.metricConnectors, { params });
  }

  getContracts(params: any = {}): any {
    return this.httpClient.get(routes.contracts, { params });
  }

  getMetricCondition(): any {
    return this.httpClient.get(routes.metricCondition);
  }

  getTargetFormat(): any {
    return this.httpClient.get(routes.metricTargetFormat);
  }

  getMetricSubFormat(): any {
    return this.httpClient.get(routes.metricTargetSubFormat);
  }

  getMetricPeriod(): any {
    return this.httpClient.get(routes.metricPeriod);
  }

  addMetricCatalog(payload: any): any {
    return this.httpClient.post(routes.metricCatalog, payload);
  }

  publishMetricVersion(payload: any): any {
    return this.httpClient.post(`${routes.metricCatalog}/publish`, payload);
  }

  getCatalogHeaders(params: any = {}): any {
    return this.httpClient.get(`${routes.metricCatalogHeaders}`, { params });
  }

  getCatalogData(body: any, params: any = {}): any {
    return this.httpClient.post(`${routes.metricCatalogData}`, body, { params });
  }

  getUDFInfo(params: any): any {
    return this.httpClient.get(`${routes.udfRoute}/${params.type}`);
  }

  getViewMetric(id: number): any {
    return this.httpClient.get(`${routes.metricDetails}${id}`);
  }

  getMetricConnectorParams(id: number): any {
    return this.httpClient.get(`${routes.metricConnectorsParams}/${id}`);
  }

  editCatalogDetails(payload: any): any {
    return this.httpClient.patch(routes.metricCatalog, payload);
  }

  publishEditCatalog(payload: any): any {
    return this.httpClient.patch(`${routes.metricCatalog}/publish`, payload);
  }
}
