<div class="right-sidebar-header">
  <h5 class="px-4 fw-bold d-inline" translate>
    MODULES.DISTRIBUTIONS.VIEW_DISTRIBUTIONS.UPLOAD
  </h5>
  <p-divider styleClass="mb-0"></p-divider>
</div>

<div class="px-4">
  <form [formGroup]="uploadForm" *ngIf="uploadForm?.controls">
    <div class="row mt-2">
      <ng-container>
        <div
          class="col-12"
          *ngFor="
            let controls of Object.keys(uploadForm.controls);
            let i = index
          "
        >
          <span class="w-100 p-float-label my-1">
            <input
              class="w-100 p-inputtext-sm"
              type="text"
              pInputText
              [formControlName]="controls"
            />
            <label
              for="{{ controls }}"
              *ngIf="headerRes[i].fieldName.toLowerCase() === controls"
            >
              {{ headerRes[i]['displayName'] }}
            </label>
          </span>
        </div>
      </ng-container>
    </div>
  </form>

  <ng-container *ngIf="!uploadForm?.controls">
    <div class="row mt-2">
      <div class="col-12 mb-1">
        <p-skeleton></p-skeleton>
      </div>
      <div class="col-12">
        <p-skeleton></p-skeleton>
      </div>
    </div>
  </ng-container>

  <!--Upload -->
  <div class="row mt-2">
    <div class="col-12">
      <!-- TODO: REMOVE THIS -->
      <!-- <pre>Progress => {{ fileUploadElement?.progress }} </pre> -->
      <span class="w-100 p-float-label my-1">
        <p-fileUpload
          #fileUploader
          name="file[]"
          mode="advanced"
          [showUploadButton]="false"
          [showCancelButton]="false"
          (onSelect)="uploadReportKPI($event)"
          [uploadLabel]="getCustomChooseLabel()"
          [chooseLabel]="
            'MODULES.DISTRIBUTIONS.VIEW_DISTRIBUTIONS.UPLOAD' | translate
          "
          [invalidFileSizeMessageSummary]="
            'File size exceeds the maximum allowed size.'
          "
          [invalidFileTypeMessageSummary]="getInvalidFileTypeMessageSummary()"
          [invalidFileTypeMessageDetail]="getInvalidFileTypeMessageDetail()"
          [customUpload]="true"
          accept=".csv, .xlsx , .xls "
          [maxFileSize]="104857600"
          (onRemove)="clearUploadValue($event)"
          (onProgress)="onUploadProgress($event)"
        >
          <ng-template pTemplate="content">
            <div class="custom-file-upload">
              <span class="fnt-size"> {{ getCustomChooseLabel() }} </span>
            </div>
          </ng-template>
        </p-fileUpload>
      </span>
    </div>
  </div>

  <!-- api error msg block -->
  <ng-container *ngIf="errorMsgList?.length">
    <div class="error-block">
      <li *ngFor="let msg of errorMsgList">{{ msg }}</li>
    </div>
  </ng-container>

  <div class="row mt-2">
    <div class="col-4">
      <button
        class="w-full p-button"
        pButton
        type="submit"
        label="{{ 'MODULES.DISTRIBUTIONS.SAVE' | translate }}"
        [disabled]="isFileSubmit"
        (click)="saveReportKPI()"
      ></button>
    </div>

    <div class="col-4">
      <button
        class="w-full p-button secondary-btn"
        pButton
        type="submit"
        label="{{ 'MODULES.DISTRIBUTIONS.CANCEL' | translate }}"
        (click)="resetForm()"
      ></button>
    </div>
  </div>
</div>
