import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { DependentFile, DetailsMenuItems, StatisticsTableLoadEvent, TabKeys } from '../components/models';

const routes = {
  hierarchyByType: `${environment.SLMUrl}/hierarchies/getTreeByHierarchyName/Folders`,
  VisualDesignHeaders: `${environment.SLMUrl}/reports`,
  VisualDesignData: `${environment.SLMUrl}/reports`,
  VDDetailsData: `${environment.SLMUrl}/calculation/flows`,
};

@Injectable({
  providedIn: 'root',
})
export class VisualDesignerService {
  updatedTemplateVisualDesign = new Subject<any>();

  viewNodeData = new BehaviorSubject<any>('');

  private isNodeHighlighted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private businessId: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  flowDetails = new BehaviorSubject<DetailsMenuItems>(this.createDefaultDataMenuItems());

  previousFlowDetails: DetailsMenuItems = this.createDefaultDataMenuItems();

  private highlightGraphNodeById: Subject<number> = new BehaviorSubject<number>(0);

  highlightGraphNodeById$: Observable<number> = this.highlightGraphNodeById.asObservable();

  selectedTab: Subject<TabKeys> = new BehaviorSubject<TabKeys>('properties');

  selectedTab$: Observable<TabKeys> = this.selectedTab.asObservable();

  private showStatisticsTable: Subject<StatisticsTableLoadEvent> = new BehaviorSubject<StatisticsTableLoadEvent>({
    levelId: 'runHistoryTable',
    clearNagiationStack: false,
    title: this.previousFlowDetails.properties.title,
  });

  showStatisticsTable$: Observable<StatisticsTableLoadEvent> = this.showStatisticsTable.asObservable();

  private pushToNavigationStack: Subject<StatisticsTableLoadEvent> = new BehaviorSubject<StatisticsTableLoadEvent>({
    levelId: 'runHistoryTable',
    clearNagiationStack: false,
    title: this.previousFlowDetails.properties.title,
  });

  pushToNavigationStack$: Observable<StatisticsTableLoadEvent> = this.pushToNavigationStack.asObservable();

  navigationStack: any[] = [];

  private deSelectNode: Subject<void> = new Subject<void>();

  deSelectNode$: Observable<void> = this.deSelectNode.asObservable();

  showOutput: Subject<boolean> = new Subject<boolean>();

  showOutput$: Observable<boolean> = this.showOutput.asObservable();

  subscriptions = this.pushToNavigationStack$.subscribe(event => this.updateNavigationStack(event));

  // capture ids when redirect from dependency tabs
  dependentFileID = new BehaviorSubject<any>({});

  constructor(private httpClient: HttpClient) {}

  updateHighlightGraphNodeById(nodeId: number): void {
    this.highlightGraphNodeById.next(nodeId);
  }

  updateDeSelectNode(): void {
    this.deSelectNode.next();
  }

  updateBusinessId(id: number): void {
    this.businessId.next(id);
  }

  updateNodeHighlight(id: number): void {
    this.isNodeHighlighted.next(true);
  }

  latestBusinessId(): number {
    return this.businessId.getValue();
  }

  updateNavigationStack(event: StatisticsTableLoadEvent): void {
    if (event.clearNagiationStack) {
      this.navigationStack = [];
    }
    if (event) {
      this.navigationStack.push(event);
    }
  }

  updatePushToNavigationStack(event: StatisticsTableLoadEvent): void {
    this.pushToNavigationStack.next(event);
  }

  updateShowStatisticsTable(table: StatisticsTableLoadEvent): void {
    this.showStatisticsTable.next(table);
  }

  updateSelectedTab(tabKey: TabKeys): void {
    this.selectedTab.next(tabKey);
  }

  updateShowOutput(isVisible: boolean): void {
    this.showOutput.next(isVisible);
  }

  getHierarchyNodeData(data: any): void {
    this.viewNodeData.next(data);
  }

  getUpdatedVisualDesign(data: any): void {
    this.updatedTemplateVisualDesign.next(data);
  }

  getVDHierarchyByType(params: any = {}): any {
    return this.httpClient.get(`${routes.hierarchyByType}`, { params });
  }

  getVisualDesignHeaders(id: number): any {
    return this.httpClient.get(`${routes.VisualDesignHeaders}/${id}/header`);
  }

  getVisualDesignData(id: number, body: any, params: any = {}): any {
    return this.httpClient.post(`${routes.VisualDesignData}/${id}/data`, body, { params });
  }

  // Observable that components can subscribe to
  get flowDetails$(): Observable<DetailsMenuItems> {
    return this.flowDetails.asObservable();
  }

  // Method to update flow details
  updateFlowDetails(details: DetailsMenuItems): void {
    this.flowDetails.next(details); // Emits new value to all subscribers
  }

  getStatisticsData(flowId: number): Observable<any> {
    return this.httpClient.get(`${routes.VDDetailsData}/${flowId}/instances`, {});
  }

  getStatisticsDetailsData(flowId: number, statisticsId: number): Observable<any> {
    return this.httpClient.get(`${routes.VDDetailsData}/${flowId}/instances/${statisticsId}/details`, {});
  }

  createDefaultDataMenuItems(): DetailsMenuItems {
    return {
      properties: {
        title: '',
        id: 0,
        description: '',
      },
      statistics: [
        {
          dataTime: '',
          rows: null,
          result: '',
          instanceId: 0,
          status: '',
        },
      ],
      dependencies: {
        reports: [],
        metric_connectors: [],
        data_flows: [],
      },
      isFlow: true,
      kpu: '',
    };
  }

  getDependencies(flowId: number, type: 'reports' | 'metricConnectors' | 'dataFlows'): Observable<any> {
    let endpoint = '';

    switch (type) {
      case 'reports':
        endpoint = 'getLinkedReports';
        break;
      case 'metricConnectors':
        endpoint = 'getLinkedMetricConnectors';
        break;
      case 'dataFlows':
        endpoint = 'getLinkedDataSources';
        break;
      default:
        break;
    }

    return this.httpClient.get(`${routes.VDDetailsData}/${flowId}/${endpoint}`);
  }

  getDependentFileID(data: DependentFile): void {
    this.dependentFileID.next(data);
  }
}
