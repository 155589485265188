import { Component, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject, combineLatest, filter, Subject, Subscription, takeUntil } from 'rxjs';

import { SearchService } from '@app/layout/services/search.service';
import { Metrics } from '@app/slm/interface/metrics.tree';
import { OrganizationService } from '@app/slm/services/organization.service';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent implements OnInit, OnDestroy {
  metricTreeData!: Metrics[];

  hierarchiesData: any = [];

  selected: any = [];

  metricsTableData: any = [];

  hierarchieName!: string;

  isViewOrganization = false;

  businessId = '';

  routeFilters: any = null; // For Global Seach handling

  private metricTreeData$ = new BehaviorSubject<any[]>([]); // Observable for tree data

  private subscription: Subscription = new Subscription();

  private destroy$ = new Subject<void>(); // Observable for cleanup

  constructor(private metricService: OrganizationService, private searchService: SearchService) {
    this.metricsTableData = [];
  }

  ngOnInit(): void {
    this.getMetricTreeData();
    this.getHierarchies();
    // Combine the tree and search data streams
    combineLatest([
      this.metricTreeData$.asObservable(), // Observable for tree data
      this.searchService.selectedItem$, // Observable for search data
    ])
      .pipe(
        takeUntil(this.destroy$), // Clean up subscription on destroy
        filter(([treeData, searchItem]) => {
          // Ensure tree data and search item are valid
          return treeData.length > 0 && !!searchItem?.hierarchyAttributes;
        })
      )
      .subscribe(([_, searchItem]) => {
        if (!searchItem?.hierarchyAttributes) {
          return;
        }
        this.expandTreeForSearchItem(searchItem.hierarchyAttributes); // Expand the tree
      });
  }

  getHierarchies(): void {
    this.metricService.getHierarchies().subscribe((res: any) => {
      if (res) {
        this.hierarchiesData = res;
      }
    });
  }

  getMetricTreeData(): void {
    this.selected = null;
    const treeDataSubscription = this.metricService.getMetricTreeData().subscribe((res: any) => {
      if (res) {
        this.metricTreeData = [res]; // Assign the tree data for PrimeNG
        this.assignParentReferences(this.metricTreeData); // Add parent references for traversal

        // Select and expand the root node
        const [rootNode] = this.metricTreeData;

        rootNode.expanded = true; // Expand root node by default
        this.selected = rootNode; // Mark root node as selected
        this.hierarchieName = this.selected.name;

        this.metricTreeData$.next(this.metricTreeData); // Push the tree data to the observable
      }
    });

    this.subscription.add(treeDataSubscription);
  }

  nodeSelect(): void {
    // expand-collapse on node
    if (this.selected.key !== '0') {
      this.selected.expanded = !this.selected.expanded;
    }

    // to check last node
    if (this.selected.children && this.selected.children.length === 0) {
      this.selected.expanded = !this.selected.expanded;
    }
    const { children } = this.selected;

    if (children.length === 0 && this.businessId !== this.selected.businessId) {
      this.businessId = this.selected.businessId;
    }
  }

  getOrganizationData(): void {
    this.isViewOrganization = !this.isViewOrganization;

    const managementDetails = this.hierarchiesData.find((item: any) => this.hierarchieName === item.name);

    this.metricService.hierarchyManagementData({ nodeInfo: this.selected, managementDetails });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.selected = null; // Clear selection
    this.metricTreeData = []; // Clear tree data
    this.metricTreeData$.next([]); // Emit cleared data to subscribers
    this.destroy$.next(); // Complete the destroy$ subject
    this.destroy$.complete();
    this.searchService.setSelectedItem(null); // Reset selected item
  }

  private expandTreeForSearchItem(hierarchyAttributes: any): void {
    const { rootNodeId, fullPathId } = hierarchyAttributes;

    // Find the root node matching rootNodeId
    const rootNode = this.metricTreeData$.getValue().find(node => node.businessId === rootNodeId);

    if (!rootNode) {
      return;
    }
    // Expand nodes along the path specified by fullPathId
    this.expandPath(rootNode, fullPathId);
  }

  // Recursive function to expand the path
  private expandPath(node: any, fullPathId: number[], currentIndex = 0): void {
    // eslint-disable-next-line no-param-reassign
    node.expanded = true; // Expand the current node

    // Check if this is the target node
    if (node.businessId === fullPathId[fullPathId.length - 1]) {
      this.selected = node; // Mark as selected
      this.businessId = this.selected.businessId;

      return;
    }

    // If there are more levels to traverse
    if (node.children && node.children.length > 0 && currentIndex < fullPathId.length - 1) {
      // Find the next node in the path
      const nextNode = node.children.find(
        (child: { businessId: number }) => child.businessId === fullPathId[currentIndex + 1]
      );

      if (nextNode) {
        // Recursively expand the next node
        this.expandPath(nextNode, fullPathId, currentIndex + 1);
      }
    }
  }

  // Assign parent references recursively (unchanged)
  private assignParentReferences(nodes: any[], parent: any = null): void {
    nodes.forEach(node => {
      // eslint-disable-next-line no-param-reassign
      node.parent = parent; // Set parent reference
      if (node.children && node.children.length > 0) {
        this.assignParentReferences(node.children, node);
      }
    });
  }
}
