<div class="right-sidebar-header">
  <h5 class="px-4 fw-bold">Drill down Reports</h5>
  <p-divider styleClass="mb-0"></p-divider>
</div>

<div class="container-fluid mt-2">
  <div class="row">
    <div class="col-12 col-sm-12">
      <ag-grid-angular
        style="width: 100%; height: calc(100vh - 92px)"
        class="ag-theme-balham custom-ag-table ps-2"
        [columnDefs]="headers"
        [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)"
        (cellClicked)="onCellClick($event)"
        (sortChanged)="onSortChange()"
        (filterModified)="onFilterModified($event)"
        [suppressRowTransform]="true"
        #agGrid
      >
      </ag-grid-angular>
    </div>
  </div>
</div>

<!-- upload right sidebar -->
<p-sidebar
  id="upload-sidebar"
  [(visible)]="isUpload"
  position="right"
  styleClass="p-sidebar-md"
  [dismissible]="false"
  [closeOnEscape]="false"
  *ngIf="isUpload"
>
  <app-report-upload (closeClicked)="isUpload = $event"></app-report-upload>
</p-sidebar>

<!-- webform right sidebar -->
<p-sidebar
  [(visible)]="isAddEnabled"
  position="right"
  styleClass="p-sidebar-md"
  [dismissible]="false"
  [closeOnEscape]="false"
  *ngIf="isAddEnabled"
>
  <app-webform (closeClicked)="isAddEnabled = $event"></app-webform>
</p-sidebar>
