import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ValidationService } from '@app/core/services/validation.service';

@Component({
  selector: 'app-edit-metric',
  templateUrl: './edit-metric.component.html',
  styleUrls: ['./edit-metric.component.scss'],
})
export class EditMetricComponent implements OnChanges {
  @Output() formDataChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input() metricGeneralDetails: any;

  editMetricForm!: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.editMetricForm = this.formBuilder.group({
      id: ['', [Validators.maxLength(512)]],
      name: ['', [ValidationService.requiredValidator, Validators.maxLength(250)]],
    });
  }

  ngOnChanges(): void {
    // Subscribe to form value changes after form initialization and patching
    if (this.editMetricForm) {
      this.editMetricForm.valueChanges.subscribe(() => {
        // Emit the payload on form value change
        this.simulateDataAsPerAPI();
      });
    }

    this.editMetricForm.patchValue({
      id: this.metricGeneralDetails?.metricId,
      name: this.metricGeneralDetails?.metricName,
    });
  }

  simulateDataAsPerAPI(): any {
    const payload = {
      metricId: this.editMetricForm.get('id')?.value,
      metricName: this.editMetricForm.get('name')?.value,
    };

    this.formDataChanged.emit(payload);
  }
}
