<div class="right-sidebar-header">
  <h5 class="fw-bold">

    <!-- Icon for status -->
    <img class="icon" [src]="getStatusIcon()" *ngIf="viewData?.status" />

    <ng-container *ngIf="viewData?.metricName">
      {{ viewData?.metricName }} {{ 'MODULES.VIEW_METRICS.DETAILS' | translate }}
    </ng-container>

    <div class="flex align-items-center" *ngIf="!viewData?.metricName">
      <p-skeleton shape="circle" size="1.5rem" styleClass="mr-2"></p-skeleton>
      <p-skeleton width="30rem"></p-skeleton>
    </div>
  </h5>
</div>

<!-- content section -->
<div class="right-sidebar-content">
  <!-- Edit btn -->
  <div class="py-0 mt-4">
    <ng-container *ngIf="!hasPublishedOrRevokedVersion() && !isEditVersionEnabled
      && !isAddVersionEnabled">
      <div class="col-2 ms-3 py-0">
        <button
          pButton
          type="button"
          class="p-button-outlined"
          label="{{ 'MODULES.SLM.EDIT_METRIC' | translate }}"
          (click)="triggerEditVersion(); isEditUDFEnabled = true"
        >
          <img src="assets/svg_icons/edit.svg" class="edit-icon" />
        </button>
      </div>
    </ng-container>
  </div>

  <!-- Tab view -->
  <ng-container *ngIf="!isEditUDFEnabled">
    <div class="tab-wrapper">
      <div class="tab-header">
        <p
          *ngFor="let menuItem of MetricMenuItems"
          class="mb-0 p-3"
          [ngClass]="{ active: selectedTab === menuItem }"
          role="button"
          (click)="onMetricTabClicked(menuItem)"
        >
          {{ MetricMenuItemNames[menuItem] | translate }}
        </p>
      </div>

      <div class="tab-content px-4">
        <ng-container [ngSwitch]="selectedTab">
          <ng-container *ngSwitchCase="'general'">
            <ng-container *ngTemplateOutlet="generalView"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'parameter'">
            <ng-container *ngTemplateOutlet="parameterView"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'CustomField'">
            <ng-container *ngTemplateOutlet="customFieldView"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'relatedObject'">
            <ng-container *ngTemplateOutlet="relatedObjectView"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <ng-template #generalView>
      <div class="row general-view g-0">
        <div class="col-8">
          <div class="row pb-10">
            <div class="col-3 pt-0">
              <label class="fw-bold" translate>
                MODULES.VIEW_METRICS.METRIC_ID
              </label>
              <div class="mt-1 metric-data">
                {{ viewData?.metricId }}
                <p-skeleton *ngIf="!viewData?.metricId"></p-skeleton>
              </div>
            </div>

            <div class="col-3 pt-0">
              <label class="fw-bold" translate>
                MODULES.VIEW_METRICS.NAME
              </label>
              <div class="mt-1 metric-data">
                {{ viewData?.metricName }}
                <p-skeleton *ngIf="!viewData?.metricName"></p-skeleton>
              </div>
            </div>

            <div class="col-3 pt-0">
              <label class="fw-bold" translate>
                MODULES.VIEW_METRICS.BUSINESS_SERVICE</label
              >

              <div class="mt-1 metric-data">
                {{ removePrefix(viewData?.businessService) }}
                <p-skeleton *ngIf="!viewData?.businessService"></p-skeleton>
              </div>
            </div>

            <div class="col-3 pt-0">
              <label class="fw-bold" translate>
                MODULES.VIEW_METRICS.CONTRACT</label
              >

              <div class="mt-1 metric-data">
                {{ viewData?.contractName }}
                <p-skeleton *ngIf="!viewData?.contractName"></p-skeleton>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-3 pt-0">
              <label class="fw-bold" translate>MODULES.METRICS.CUSTOMER</label>
              <div class="mt-1 metric-data">
                {{ viewData?.customerName }}
                <p-skeleton *ngIf="!viewData?.customerName"></p-skeleton>
              </div>
            </div>

            <div class="col-3 pt-0">
              <label class="fw-bold" translate> MODULES.METRICS.PROVIDER</label>
              <div class="mt-1 metric-data">
                {{ viewData?.provider }}
                <p-skeleton *ngIf="!viewData?.provider"></p-skeleton>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 pl-0">
          <div class="col-12 px-0 left-view pt-0">
            <label class="fw-bold" translate>
              MODULES.VIEW_METRICS.DESCRIPTION
            </label>
            <div class="mt-1 parameter-data">
              {{ viewData?.description }}
              <p-skeleton *ngIf="!viewData?.description"></p-skeleton>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #parameterView>
      <div class="row general-view g-0 parameter-view">
        <div class="col-3 pt-0 pl-0">
          <div class="col-12 pt-0 pl-0">
            <label class="fw-bold" translate
              >MODULES.VIEW_METRICS.STATEMENT</label
            >
            <div class="mt-1 parameter-data">{{ viewData?.statement }}</div>
          </div>
        </div>
        <div class="col-3 left-view pt-0" style="height: auto">
          <div class="col-12 pt-0 pl-0">
            <label class="fw-bold" translate
              >MODULES.VIEW_METRICS.PARAMETER</label
            >
            <ng-container
              *ngIf="isArrayMetricParams; else simpleParametersTemplate"
            >
              <div class="mt-1 parameter-data">
                <ng-container *ngFor="let data of viewData.metricParamValues">
                  <p class="fw-600 parameter">
                    {{ data?.name }}:
                    <span class="fw-normal">{{
                      data?.value ? data?.value : 'N/A'
                    }}</span>
                  </p>
                </ng-container>
              </div>
            </ng-container>
            <ng-template #simpleParametersTemplate>
              <div class="mt-1 metric-data">
                {{ viewData.metricParamValues || 'N/A' }}
              </div>
            </ng-template>
          </div>
        </div>
        <div class="col-6 left-view pt-0 pb-0">
          <div class="row pb-10 g-0">
            <div class="col-4 py-0 pl-0">
              <label class="fw-bold" translate
                >MODULES.METRICS.METRIC_CONNECTOR</label
              >
              <div class="mt-1 d-flex">
                <span *ngIf="viewData?.metricConnector">
                  <img
                    src="assets/icons/connector.svg"
                    alt=""
                    width="16"
                    class="metric-img"
                /></span>
                <div>{{ viewData?.metricConnector }}</div>
              </div>
            </div>
            <div class="col-4 py-0">
              <label class="fw-bold" translate
                >MODULES.VIEW_METRICS.CONDITION</label
              >
              <div class="mt-1 metric-data">{{ viewData?.conditionName }}</div>
            </div>
            <div class="col-4 pt-0">
              <label class="fw-bold" translate
                >MODULES.VIEW_METRICS.FORMAT</label
              >
              <div class="mt-1 metric-data">
                {{ viewData?.targetFormatName }}
              </div>
            </div>
          </div>
          <div class="row g-0">
            <div class="col-4 pt-0 pl-0">
              <label class="fw-bold" translate
                >MODULES.VIEW_METRICS.SUB_FORMAT</label
              >
              <div class="mt-1 metric-data">{{ viewData?.formatName }}</div>
            </div>
            <div class="col-4 pt-0">
              <label class="fw-bold" translate
                >MODULES.VIEW_METRICS.PERIOD</label
              >
              <div class="mt-1 metric-data">{{ viewData?.periodName }}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #customFieldView>
      <div class="row general-view g-0 metric-rows">
        <ng-container
          *ngIf="
            !viewData?.objectFieldData ||
              viewData?.objectFieldData.length === 0;
            else customFieldTemplate
          "
        >
          <div class="col-12">
            {{ 'MODULES.VIEW_METRICS.NO_CUSTOM_FIELDS' | translate }}
          </div>
        </ng-container>
        <ng-template #customFieldTemplate>
          <ng-container *ngFor="let data of viewData?.objectFieldData">
            <div class="col-4 px-0">
              <label class="fw-bold" translate>{{ data?.name }}</label>
              <div class="mt-1">{{ data?.value || 'N/A' }}</div>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </ng-template>

    <ng-template #relatedObjectView>
      <div class="row general-view g-0 custom-filed" style="padding-top: 4px">
        <div class="col-7 pt-0">
          <label class="fw-bold fs-14" translate
            >MODULES.VIEW_METRICS.CATALOG_METRIC</label
          >
          <div class="row g-0">
            <div class="col-4 pl-0">
              <label class="fw-bold" translate>MODULES.VIEW_METRICS.ID</label>
              <div class="mt-1 metric-data">
                {{ viewData?.metricCatalogId }}
                <p-skeleton *ngIf="!viewData?.metricCatalogId"></p-skeleton>
              </div>
            </div>
            <div class="col-4">
              <label class="fw-bold" translate
                >MODULES.VIEW_METRICS.CAT_NAME</label
              >
              <div class="mt-1 metric-data">
                {{ viewData?.metricCatalogName }}
                <p-skeleton *ngIf="!viewData?.metricCatalogName"></p-skeleton>
              </div>
            </div>
            <div class="col-4">
              <label class="fw-bold" translate
                >MODULES.VIEW_METRICS.BUSINESS_SERVICE</label
              >
              <div class="mt-1 metric-data">
                {{ viewData?.metricCatalogBusinessService }}
                <p-skeleton
                  *ngIf="!viewData?.metricCatalogBusinessService"
                ></p-skeleton>
              </div>
            </div>
          </div>
        </div>
        <div class="col-5 left-view pt-0">
          <label class="fw-bold fs-14" translate
            >MODULES.VIEW_METRICS.FLOW</label
          >
          <ng-container
            *ngIf="isArrayFlowDetail; else simpleFlowDetailTemplate"
          >
            <div class="row g-0">
              <div class="col-12 pl-0">
                <label class="fw-bold" translate
                  >MODULES.VIEW_METRICS.CAT_NAME</label
                >
                <div class="mt-1 metric-data">
                  <div
                    *ngFor="let flowDetail of viewData?.flowDetails"
                    class="d-flex align-items-center mb-3"
                  >
                    <span class="mr-2">
                      <img
                        src="assets/icons/flow.svg"
                        alt=""
                        width="16"
                        class="metric-img"
                      />
                    </span>
                    <p class="mb-0">{{ flowDetail.name }}</p>
                  </div>
                  <p-skeleton *ngIf="!viewData?.flowDetails"></p-skeleton>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #simpleFlowDetailTemplate>
            <div class="row g-0">
              <div class="col-12 pl-0">
                <label class="fw-bold" translate
                  >MODULES.VIEW_METRICS.CAT_NAME</label
                >
                <div class="mt-1 metric-data">
                  <div class="d-flex align-items-center mb-3">
                    <div class="metric-data">
                      {{ 'N/A' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <!-- Metric Details (display if edit form is open) -->
  <ng-container *ngIf="isEditUDFEnabled">
    <div class="px-4">
      <div class="grid mt-2">
        <!--Metric connector-->
        <div class="col-4 mb-1" translate>
          <label class="fw-bold" translate>
            MODULES.METRICS.METRIC_CONNECTOR
          </label>

          <div class="mt-1">
            {{ viewData?.metricConnector }}
            <p-skeleton *ngIf="!viewData?.metricConnector"></p-skeleton>
          </div>
        </div>

        <!--Contract name-->
        <div class="col-4 mb-1">
          <label class="fw-bold" translate>
            MODULES.VIEW_METRICS.CONTRACT_NAME</label
          >
          <div class="mt-1">
            {{ viewData?.contractName }}
            <p-skeleton *ngIf="!viewData?.contractName"></p-skeleton>
          </div>
        </div>

        <!--Customer-->
        <div class="col-4 mb-1">
          <label class="fw-bold" translate>MODULES.METRICS.CUSTOMER</label>

          <div class="mt-1">
            {{ viewData?.customerName }}
            <p-skeleton *ngIf="!viewData?.customerName"></p-skeleton>
          </div>
        </div>

        <!--Provider-->
        <div class="col-4 mb-1">
          <label class="fw-bold" translate> MODULES.METRICS.PROVIDER</label>

          <div class="mt-1">
            {{ viewData?.provider }}
            <p-skeleton *ngIf="!viewData?.provider"></p-skeleton>
          </div>
        </div>

        <!--Business service-->
        <div class="col-4 mb-1">
          <label class="fw-bold" translate>
            MODULES.VIEW_METRICS.BUSINESS_SERVICE</label
          >

          <div class="mt-1">
            {{ viewData?.businessService }}
            <p-skeleton *ngIf="!viewData?.businessService"></p-skeleton>
          </div>
        </div>

        <!-- Condition -->
        <div class="col-4 mb-1">
          <label class="fw-bold" translate>
            MODULES.VIEW_METRICS.CONDITION</label
          >

          <div class="mt-1">
            {{ viewData?.conditionName }}
            <p-skeleton *ngIf="!viewData?.conditionName"></p-skeleton>
          </div>
        </div>

        <!-- Format -->
        <div class="col-4 mb-1">
          <label class="fw-bold" translate>MODULES.VIEW_METRICS.FORMAT</label>

          <div class="mt-1">
            {{ viewData?.targetFormatName }}
            <p-skeleton *ngIf="!viewData?.targetFormatName"></p-skeleton>
          </div>
        </div>

        <!-- Sub-format -->
        <div class="col-4 mb-1">
          <label class="fw-bold" translate>
            MODULES.VIEW_METRICS.SUB_FORMAT
          </label>

          <div class="mt-1">
            {{ viewData?.formatName }}
            <p-skeleton *ngIf="!viewData?.formatName"></p-skeleton>
          </div>
        </div>

        <!-- Period -->
        <div class="col-4 mb-1">
          <label class="fw-bold" translate> MODULES.VIEW_METRICS.PERIOD</label>

          <div class="mt-1">
            {{ viewData?.periodName }}
            <p-skeleton *ngIf="!viewData?.periodName"></p-skeleton>
          </div>
        </div>

        <!--Description-->
        <div class="col-4 mb-1">
          <label class="fw-bold" translate
            >MODULES.VIEW_METRICS.DESCRIPTION</label
          >
          <div class="mt-1 parameter-data">
            {{ viewData?.description }}
            <p-skeleton *ngIf="!viewData?.description"></p-skeleton>
          </div>
        </div>

        <!-- Statement -->
        <div class="col-4 mb-1">
          <label class="fw-bold" translate
            >MODULES.VIEW_METRICS.STATEMENT</label
          >

          <div class="mt-1 parameter-data">
            {{ viewData?.statement }}
            <p-skeleton *ngIf="!viewData?.statement"></p-skeleton>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Version Details -->
  <div *ngIf="!isEditVersionEnabled && !isAddVersionEnabled">
    <h5 class="header-color py-3 px-4" translate>
      MODULES.VIEW_METRICS.VERSION_DETAILS
    </h5>
    <p-divider styleClass="my-0"></p-divider>
  </div>

  <div class="px-4" *ngIf="!isEditVersionEnabled && !isAddVersionEnabled">
    <div class="row mt-2">
      <div class="col-6 col-sm-6">
        <label class="fw-bold mb-2" translate>
          MODULES.VIEW_METRICS.SELECT_VERSION
        </label>
        <ng-container *ngIf="viewVersionList.length">
          <button
            pButton
            type="button"
            class="w-full p-button-outlined custom-btn-outline"
            (click)="op.toggle($event)"
          >
            <div class="w-100 text-center">
              <span class="pe-2"
                >{{
                  selectedVersion
                    ? selectedVersion.versionName
                    : viewVersionList[0].versionName
                }}
              </span>
              <span
                [ngClass]="
                  selectedVersion.statusValue === 'Published'
                    ? 'success-chip'
                    : selectedVersion?.statusValue === 'Draft'
                    ? 'warning-chip'
                    : 'danger-chip'
                "
              >
                {{
                  selectedVersion
                    ? selectedVersion.statusValue
                    : viewVersionList[0].statusValue
                }}
              </span>
            </div>
            <i class="pi pi-angle-down"></i>
          </button>

          <p-overlayPanel
            #op
            [showCloseIcon]="true"
            styleClass="custom-overlay"
          >
            <ng-template pTemplate>
              <!-- status filter -->
              <form [formGroup]="createMetricForm">
                <div class="mb-3">
                  <span class="fw-bold pe-3 ps-2 align-middle" translate
                    >MODULES.VIEW_METRICS.FILTER_BY</span
                  >
                  <ng-container
                    formArrayName="statusFilter"
                    *ngFor="let check of statusList; let i = index"
                  >
                    <p-checkbox
                      [label]="check.label"
                      [binary]="true"
                      class="align-middle px-2"
                      [labelStyleClass]="check.class"
                      [formControlName]="i"
                      (onChange)="applyStatusFilter($event, check.label)"
                    >
                    </p-checkbox>
                  </ng-container>
                </div>
              </form>

              <p-table
                #paginationReset
                styleClass="p-datatable-sm"
                [value]="filteredVersion"
                selectionMode="single"
                [(selection)]="selectedVersion"
                (onRowSelect)="onClosePanel($event)"
                (onRowUnselect)="onClosePanel($event)"
                [paginator]="true"
                [rows]="10"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{{
                  'MODULES.PAGINATION.SHOWING' | translate
                }} {first} {{ 'MODULES.PAGINATION.TO' | translate }} {last} {{
                  'MODULES.PAGINATION.OF' | translate
                }} {{ filteredVersion.length }} {{
                  'MODULES.PAGINATION.ENTRIES' | translate
                }}"
                [scrollHeight]="'calc(100vh - 180px)'"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 110px" translate>
                      MODULES.VIEW_METRICS.VERSION_NAME
                    </th>
                    <th style="width: 90px" translate>
                      MODULES.METRICS.STATUS
                    </th>
                    <th style="width: 100px" translate>
                      MODULES.VIEW_METRICS.START_DATE
                    </th>
                    <th style="width: 100px" translate>
                      MODULES.VIEW_METRICS.END_DATE
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-version>
                  <tr [pSelectableRow]="rowData">
                    <td>{{ version.versionName }}</td>
                    <td>
                      <span
                        [ngClass]="
                          version.statusValue === 'Published'
                            ? 'success-chip'
                            : version.statusValue === 'Draft'
                            ? 'warning-chip'
                            : 'danger-chip'
                        "
                      >
                        {{ version.statusValue }}
                      </span>
                    </td>
                    <td>{{ version.startDate | date : 'yyyy-MM-dd' }}</td>
                    <td>{{ version.endDate | date : 'yyyy-MM-dd' }}</td>
                  </tr>
                </ng-template>
                <!-- no records msg -->
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td class="text-center" [attr.colSpan]="4">
                      {{ 'MODULES.EMPTY_TABLE_MSG' | translate }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </ng-template>
          </p-overlayPanel>
        </ng-container>
        <p-skeleton *ngIf="!viewVersionList.length"></p-skeleton>
      </div>

      <div class="col-6 col-sm-6 p-4 mt-2 text-right">
        <ng-container *ngIf="hasPublishedOrRevokedVersion()">
          <button
            pButton
            type="button"
            class="p-button-outlined"
            style="width: 110px"
            [disabled]="
              (selectedVersion && selectedVersion.statusValue !== 'Draft') ||
              !metricPermission.hasEditMetricVersion()
            "
            (click)="triggerEditVersion()"
            icon="pi pi-file-edit"
            label="{{ 'MODULES.METRICS.EDIT' | translate }}"
          ></button>
        </ng-container>
        <button
          pButton
          type="button"
          class="p-button-outlined ml-2"
          icon="pi pi-plus-circle"
          style="width: 110px"
          (click)="triggerAddVersion()"
          label="{{ 'MODULES.METRICS.Add' | translate }}"
          [disabled]="!metricPermission.hasAddMetricVersion()"
        ></button>
      </div>

      <div class="col-4 mb-1">
        <label class="fw-bold" translate>
          MODULES.VIEW_METRICS.VERSION_NAME
        </label>

        <div class="mt-1">
          <span *ngIf="createMetricForm.get('versionName')?.value">
            {{ createMetricForm.get('versionName')?.value }}
          </span>
          <p-skeleton
            *ngIf="!createMetricForm.get('versionName')?.value"
          ></p-skeleton>
        </div>
      </div>

      <!-- Target -->
      <div class="col-4 mb-1" translate>
        <label class="fw-bold" translate> MODULES.VIEW_METRICS.TARGET</label>

        <div class="mt-1">
          {{ createMetricForm.get('target')?.value }}
          <p-skeleton
            *ngIf="!createMetricForm.get('target')?.value"
          ></p-skeleton>
        </div>
      </div>

      <!-- Expected-->
      <div class="col-4 mb-1" translate>
        <label class="fw-bold" translate> MODULES.VIEW_METRICS.EXPECTED</label>

        <div class="mt-1">
          {{ createMetricForm.get('expected')?.value }}
          <p-skeleton
            *ngIf="!createMetricForm.get('expected')?.value"
          ></p-skeleton>
        </div>
      </div>

      <!--Start date-->
      <div class="col-4 mb-1" translate>
        <label class="fw-bold" translate>
          MODULES.VIEW_METRICS.START_DATE</label
        >

        <div class="mt-1">
          {{ createMetricForm.get('startDate')?.value | date : 'yyyy-MM-dd' }}
          <p-skeleton
            *ngIf="!createMetricForm.get('startDate')?.value"
          ></p-skeleton>
        </div>
      </div>

      <!--End date-->
      <div class="col-4 mb-1" translate>
        <label class="fw-bold" translate> MODULES.VIEW_METRICS.END_DATE</label>

        <div class="mt-1">
          {{ createMetricForm.get('endDate')?.value | date : 'yyyy-MM-dd' }}
          <p-skeleton
            *ngIf="!createMetricForm.get('endDate')?.value"
          ></p-skeleton>
        </div>
      </div>

      <!-- version statement -->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate> MODULES.CONTRACTS.NOTES</label>

        <div class="mt-1">
          {{ createMetricForm.get('metricVersionStatement')?.value }}
          <p-skeleton
            *ngIf="!createMetricForm.get('metricVersionStatement')?.value"
          ></p-skeleton>
        </div>
      </div>
    </div>
  </div>

  <!-- UDF Version Details -->
  <div *ngIf="versionUdfList && versionUdfList.length > 0">
    <ng-container *ngIf="!isEditVersionEnabled && !isAddVersionEnabled">
      <h5 translate class="mt-4 px-4">MODULES.VIEW_METRICS.UDF_VERSION</h5>
      <p-divider styleClass="mt-3 mb-2"></p-divider>
      <div class="px-4">
        <div class="row mt-2">
          <ng-container *ngFor="let udfField of versionUdfList">
            <div class="col-4 mb-1">
              <label class="fw-bold" translate>{{ udfField.name }}</label>

              <div class="mt-1">
                <span class="text-break">
                  {{
                    udfField?.formattedValue ? udfField?.formattedValue : 'N/A'
                  }}
                </span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Edit Version section -->
  <ng-container *ngIf="isEditVersionEnabled">
    <app-edit-version [viewDataMetricData]="viewData"></app-edit-version>
  </ng-container>

  <!-- Add Version -->
  <ng-container *ngIf="isAddVersionEnabled">
    <app-add-version></app-add-version>
  </ng-container>

  <ng-container *ngIf="!versionUdfList">
    <div class="row px-4 mt-2">
      <div class="col-9 mb-2">
        <p-skeleton></p-skeleton>
      </div>
      <div class="col-4" *ngFor="let item of [].constructor(6)">
        <p-skeleton></p-skeleton>
      </div>
    </div>
  </ng-container>
</div>

<!-- btn section -->
<ng-container *ngIf="!isEditVersionEnabled && !isAddVersionEnabled">
  <div class="right-sidebar-footer">
    <div class="w-100 d-flex">
      <!-- close btn -->
      <button
        pButton
        type="button"
        class="p-button-outlined me-auto"
        style="width: 110px"
        label="{{ 'BUTTONS.CLOSE' | translate }}"
        (click)="closeViewMetric()"
      ></button>

      <!-- publish btn -->
      <ng-container
        *ngIf="selectedVersion && selectedVersion.statusValue === 'Draft'"
      >
        <button
          pButton
          type="submit"
          label="{{ 'MODULES.CREATE_METRIC.PUBLISH' | translate }}"
          [disabled]="!metricPermission.hasPublishMetricVersion()"
          class="p-button"
          style="width: 110px"
          (click)="cofirmPublish($event)"
        ></button>
      </ng-container>

      <!-- revoke btn -->
      <ng-container
        *ngIf="selectedVersion && selectedVersion.statusValue === 'Published'"
      >
        <button
          pButton
          type="submit"
          class="p-button-publish"
          label="{{ 'MODULES.CREATE_METRIC.REVOKE' | translate }}"
          [disabled]="!metricPermission.hasRevokeMetricVersion()"
          (click)="cofirmRevoke($event)"
          style="width: 110px"
        ></button>
      </ng-container>
    </div>
  </div>
</ng-container>

<p-confirmPopup></p-confirmPopup>
