<app-loader></app-loader>

<div class="layout-wrapper">
  <!-- Topbar -->
  <app-topbar (clearSubmenu)="updateSubmenu([])"></app-topbar>

  <!-- Sidebar (Menu) -->
  <app-main-menu (menuItemClicked)="onMenuItemClicked($event)"></app-main-menu>

  <!-- Submenu (Child Items) Below Topbar -->
  <div class="submenu-wrapper" *ngIf="activeSubmenuItems?.length">
    <div class="submenu">
      <ng-container *ngFor="let submenuItem of activeSubmenuItems">
        <ng-container *hasPermission="submenuItem.requiredPermission">
          <p
            class="mb-0 p-3"
            [routerLink]="submenuItem.routerLink"
            [ngClass]="{ active: selectedSubmenu === submenuItem }"
            role="button"
          >
            {{ submenuItem.label | translate }}
          </p>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <!-- Main Content Rendered Based on Router -->
  <div
    class="layout-main-container"
    [ngClass]="{ 'layout-pad-top': !activeSubmenuItems.length }"
  >
    <div class="layout-main">
      <app-breadcrumb (clearSubmenu)="updateSubmenu([])"></app-breadcrumb>
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="layout-mask"></div>
</div>

<!-- Session-Expired right sidebar -->
<p-sidebar
  [(visible)]="isSessionTimeoutVisible"
  position="right"
  styleClass="p-sidebar-md"
  [showCloseIcon]="false"
  [dismissible]="false"
  [closeOnEscape]="false"
>
  <app-session-expired *ngIf="isSessionTimeoutVisible"></app-session-expired>
</p-sidebar>

<!-- primeng toast message -->
<p-toast
  [showTransformOptions]="'translateX(100%)'"
  [showTransitionOptions]="'100ms'"
  [hideTransitionOptions]="'100ms'"
  [hideTransformOptions]="'translateX(100%)'"
  [preventOpenDuplicates]="true"
></p-toast>
