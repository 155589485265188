import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map } from 'rxjs';

import { UdfService } from '@app/slm/services/udf.service';

@Component({
  selector: 'app-udf',
  templateUrl: './udf.component.html',
  styleUrls: ['./udf.component.scss'],
})
export class UdfComponent implements OnInit, OnChanges {
  @Output() formDataChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input() udfInfo: any;

  dynamicForm!: FormGroup;

  udfSelectedData: any;

  uploadedFiles: File[] = [];

  errorMsgList: any = [];

  metricObjectFields: any[] = [];

  metricVersionObjectFields: any[] = [];

  formControlsInitialized = false;

  loading = true;

  valueSetMap: any;

  nullData: any = { key: '', title: ' ' };

  minDate: Date | any;

  maxDate: Date | any;

  constructor(
    private formBuilder: FormBuilder,
    private udfService: UdfService,
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.udfService.viewUDFData.subscribe((res: any) => {
      if (res) {
        this.udfSelectedData = res;
      }
    });
  }

  ngOnChanges(): void {
    this.buildForm();
  }

  buildForm(): void {
    // Check if the dynamicForm FormGroup exists
    if (this.dynamicForm) {
      // Iterate over the keys (control names) of the dynamicForm's controls
      Object.keys(this.dynamicForm.controls).forEach(key => {
        // Remove each control from the dynamicForm FormGroup
        this.dynamicForm.removeControl(key);
      });

      // Set the formControlsInitialized flag to false, indicating that form controls are being reset
      this.formControlsInitialized = false;

      // Re-enable the entire dynamicForm FormGroup
      this.dynamicForm.enable();
    }

    if (this.udfInfo && this.udfInfo.length > 0) {
      const valueSetIds: number[] = this.udfInfo
        .filter((udfData: any) => udfData.objectFieldType === 'Value Set' && udfData.valueSetId)
        .map((udfData: any) => udfData.valueSetId);

      if (valueSetIds.length > 0) {
        const valueSetRequests = valueSetIds.map(id =>
          this.udfService.getValueSet(id).pipe(map((res: any) => ({ id, data: res?.valueSetRowDtoList })))
        );

        forkJoin(valueSetRequests).subscribe((responses: any[]) => {
          // Map responses to their respective fields (udfData)
          this.valueSetMap = this.udfInfo.reduce((acc: { [x: string]: any[] }, udfData: any) => {
            if (udfData.objectFieldType === 'Value Set') {
              const response = responses.find((res: any) => res.id === udfData.valueSetId);

              if (response) {
                // Determininig options based on mandatory status - to append null data to droddown
                acc[udfData.valueSetId] = udfData.isMandatory
                  ? [...response.data] // Exclude nullData for mandatory fields
                  : [{ key: '', title: ' ' }, ...response.data]; // Include nullData for non-mandatory fields
              }
            }

            return acc;
          }, {});
          this.buildFormInsideSubscription();
        });
      } else {
        this.buildFormInsideSubscription(); // invoke buildFormInsideSubscription directly if there are no valueSetIds
      }
    }
  }

  buildFormInsideSubscription(): void {
    const formControlsConfig: { [key: string]: any } = {};

    if (this.udfInfo && this.udfInfo.length > 0) {
      this.udfInfo.forEach((data: any) => {
        if (!data.fieldName || !data.objectFieldType) {
          // Skip this iteration if fieldName or objectFieldType is null or undefined
          return;
        }
        // Set validators for each control after date constraints are set
        const validators = this.setControlsValidators(data);

        // Configure each form control with initial value and validators
        formControlsConfig[data.fieldName] = [null, validators];
      });
    }

    // Initialize dynamicForm with form controls configuration
    this.dynamicForm = this.formBuilder.group(formControlsConfig);
    this.formControlsInitialized = true;

    // Disable form if status is Published or Revoked
    if (
      this.udfSelectedData &&
      (this.udfSelectedData.statusValue === 'Published' || this.udfSelectedData.statusValue === 'Revoked')
    ) {
      this.dynamicForm.disable();
    } else {
      this.dynamicForm.enable();
    }

    // Subscribe to form value changes after form initialization and patching
    if (this.dynamicForm) {
      this.dynamicForm.valueChanges.subscribe(value => {
        // Emit the payload on form value change
        this.simulateDataAsPerAPI(value);
      });
    }

    this.patchCFValues();

    this.loading = false;
  }

  patchCFValues(): void {
    this.udfInfo.forEach((data: any) => {
      const control = this.dynamicForm.get(data.fieldName);

      if (!control) {
        return;
      }

      // Handle Value Set type
      if (data.objectFieldType === 'Value Set' && this.valueSetMap[data.valueSetId]) {
        const valueSetArray = this.valueSetMap[data.valueSetId];
        const matchingElement = valueSetArray.find((element: any) => element.title === data.value);

        if (matchingElement) {
          control.patchValue(matchingElement.key);
        }
      }
      // Handle date-related types
      else if (['Date', 'Date and Time', 'Month', 'Year'].includes(data.objectFieldType)) {
        let valueToPatch = data.formattedValue;

        // Convert formattedValue to Date if it’s a valid date string and not already a Date object
        if (valueToPatch && typeof valueToPatch === 'string' && !Number.isNaN(Date.parse(valueToPatch))) {
          valueToPatch = new Date(valueToPatch);
        }
        // If no formattedValue, fallback to data.value as a Date object if it’s valid
        else if (!valueToPatch && data.value && !Number.isNaN(Date.parse(data.value))) {
          valueToPatch = new Date(data.value);
        }

        control.patchValue(valueToPatch);
      }
      // Handle Number type
      else if (data.value && data.objectFieldType === 'Number') {
        const valueToPatch = !Number.isNaN(Number(data.value)) ? data.value : null;

        control.patchValue(valueToPatch);
      }
      // Default case for other types
      else {
        control.patchValue(data.value);
      }
    });
  }

  // Formats a Date object as "yyyy-mm-dd" (date only, no time zone)
  formatDateOnly(date: Date | null): string | null {
    return date ? date.toISOString().split('T')[0] : null;
  }

  // Formats a Date object as "yyyy-mm-dd HH:mm:ss" (date and time, no time zone)
  formatDateTime(date: Date | null): string | null {
    if (!date) {
      return null;
    }
    const [datePart, timePart] = date.toISOString().split('T');

    return `${datePart} ${timePart.split('.')[0]}`; // Remove milliseconds and time zone
  }

  /**
   *  Sets the validators for a given form field based on its type and constraints.
   *
   * @param field The field configuration object containing details about the field such as type, constraints, and requirements from api for each field.
   * @returns
   */
  setControlsValidators(field: any): any {
    const validators: any = [];

    // 1. Date Constraints: Apply minDate and maxDate constraints to fields of date-related types.
    if (['Date', 'Date and Time', 'Month', 'Year'].includes(field.objectFieldType)) {
      this.setDateConstraints(field);
    }

    // 2. Required Validator: If the field is mandatory, add a required validator.
    if (field.isMandatory) {
      validators.push(Validators.required);
    }

    // For number fields, use min and max validators
    if (field.objectFieldType === 'Number') {
      if (field.minimumValue) {
        validators.push(Validators.min(field.minimumValue));
      }
      if (field.maximumValue) {
        validators.push(Validators.max(field.maximumValue));
      }
    }

    // 4. Date Validators: Apply direction-specific validators (past/future) and min/max date constraints.
    if (['Date', 'Date and Time', 'Month', 'Year'].includes(field.objectFieldType)) {
      // Apply direction-specific validation if a direction (e.g., "Only Past", "Future") is set.
      if (field.direction) {
        validators.push(this.dateDirectionValidator(field.direction, field.minDate, field.maxDate));
      }
      // If no specific direction is provided (or "Both" is allowed), apply minDate and maxDate validators as well.
      if ((field.direction === 'Both' || !field.direction) && field.minDate) {
        validators.push(this.minimumDateValidator(field.minDate));
      }
      if ((field.direction === 'Both' || !field.direction) && field.maxDate) {
        validators.push(this.maximumDateValidator(field.maxDate));
      }
    }

    // 5. String Validators: Apply minimum and maximum length constraints for string fields.
    if (field.objectFieldType === 'String' || field.objectFieldType === 'Text') {
      if (field.minimumValue) {
        validators.push(Validators.minLength(field.minimumValue));
      }
      if (field.maximumValue) {
        validators.push(Validators.maxLength(field.maximumValue));
      }
    }

    // 6. Pattern Validator: Apply a regex pattern validator if specified in the field configuration.
    if (field.patternValidation) {
      validators.push(Validators.pattern(field.patternValidation));
    }

    return validators;
  }

  /**
   * Validator function to ensure that the selected date is not earlier than a specified minimum date.
   *
   * @param minDate - The minimum allowed date for the field. Any date earlier than this will trigger a validation error.
   * @returns A validation function that checks if the field’s value meets the minimum date requirement.
   */
  minimumDateValidator(minDate: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const value = new Date(control.value);

      if (minDate && value < minDate) {
        return { minDate: { requiredDate: minDate } }; // Error format
      }

      return null; // No error
    };
  }

  /**
   * Validator function to ensure that the selected date is not later than a specified maximum date.
   *
   * @param maxDate - The maximum allowed date for the field. Any date later than this will trigger a validation error.
   * @returns A validation function that checks if the field’s value meets the maximum date requirement.
   */
  maximumDateValidator(maxDate: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const value = new Date(control.value);

      if (maxDate && value > maxDate) {
        return { maxDate: { requiredDate: maxDate } }; // Error format
      }

      return null; // No error
    };
  }

  // Function to parse a date string as a "plain" date (year, month, day only)
  parseDateAsPlainDate(dateString: string): Date | null {
    if (!dateString) {
      return null;
    }
    const [datePart] = dateString.split('T');
    const [year, month, day] = datePart.split('-').map(Number);

    return new Date(year, month - 1, day);
  }

  /**
   * Sets the minimum and maximum date constraints for a field based on its objectFieldType.
   *
   * @param field - The field configuration object containing information such as objectFieldType,
   *                minimumDate, and maximumDate, which dictate the allowed date range for the field.
   */
  setDateConstraints(field: any): void {
    // Step 1: Parse minimum and maximum dates as plain dates (no timezone adjustments)
    const min = field.minimumDate ? this.parseDateAsPlainDate(field.minimumDate) : null;
    const max = field.maximumDate ? this.parseDateAsPlainDate(field.maximumDate) : null;

    // Get today's date (without time for accurate comparison)
    const today = new Date();

    today.setHours(0, 0, 0, 0);

    // Step 2: Apply direction-specific constraints if direction is provided
    let adjustedMinDate = min;
    let adjustedMaxDate = max;

    switch (field.direction) {
      case 'Future':
        // Set minDate to the day after today if direction is "Future"
        adjustedMinDate = min && min > today ? min : new Date(today.getTime() + 24 * 60 * 60 * 1000); // Adds 24 hours to today’s date to create tomorrow’s date.
        break;
      case 'Only Past':
        // Set maxDate to the day before today if direction is "Only Past"
        adjustedMaxDate = max && max < today ? max : new Date(today.getTime() - 24 * 60 * 60 * 1000);
        break;
      case 'Both':
      default:
        // No change for "Both" or if direction is not provided
        adjustedMinDate = min;
        adjustedMaxDate = max;
        break;
    }

    // Step 3: Apply date constraints based on the objectFieldType
    if (field.objectFieldType === 'Date' || field.objectFieldType === 'Date and Time') {
      /* eslint-disable no-param-reassign */
      field.minDate = adjustedMinDate;
      field.maxDate = adjustedMaxDate;
    } else if (field.objectFieldType === 'Month') {
      /* eslint-disable no-param-reassign */
      field.minDate = adjustedMinDate ? new Date(adjustedMinDate.getFullYear(), adjustedMinDate.getMonth(), 1) : null; // Creates a new date object for the first day of the minDate month.
      field.maxDate = adjustedMaxDate
        ? new Date(adjustedMaxDate.getFullYear(), adjustedMaxDate.getMonth() + 1, 0) // Creates a new date object for the last day of the maxDate month.
        : null;
    } else if (field.objectFieldType === 'Year') {
      /* eslint-disable no-param-reassign */
      field.minDate = adjustedMinDate ? new Date(adjustedMinDate.getFullYear(), 0, 1) : null; // Sets minDate to January 1 of the minDate year.
      field.maxDate = adjustedMaxDate ? new Date(adjustedMaxDate.getFullYear(), 11, 31) : null; // Sets maxDate to December 31 of the maxDate year.
    }
  }

  /**
   * Validator function to enforce date constraints based on a specified direction (Future, Only Past, or Both),
   * along with optional minDate and maxDate limits.
   *
   * @param direction - The direction constraint for the date input ("Future", "Only Past", or "Both").
   * @param minDate - The earliest allowed date, if specified.
   * @param maxDate - The latest allowed date, if specified.
   * @returns A validation function that checks if the date input meets the specified constraints.
   */

  // Custom Validator for Date Direction
  dateDirectionValidator(direction: string, minDate: Date | null, maxDate: Date | null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // Step 1: Check if the control has a value
      if (!control.value) {
        return null;
      }

      const value = new Date(control.value);

      const today = new Date(); // Get today's date and zero out the time for date-only comparison

      today.setHours(0, 0, 0, 0); // Set time to midnight for date-only comparison

      // Step 2: Apply "Future" constraint, allowing only dates after today
      if (direction === 'Future') {
        if (value <= today) {
          return { futureDate: { requiredDate: today } }; // Include today's date
        }
        if (minDate && value < minDate) {
          return { minDate: { requiredDate: minDate } };
        }
      }

      // Step 3: Apply "Only Past" constraint, allowing only dates before today
      if (direction === 'Only Past') {
        if (value >= today) {
          return { pastDate: { requiredDate: today } }; // Include today's date
        }
        if (maxDate && value > maxDate) {
          return { maxDate: { requiredDate: maxDate } };
        }
        if (minDate && value < minDate) {
          return { minDate: { requiredDate: minDate } };
        }
      }

      // Step 4: Apply "Both" constraint or no direction (allowing any date within min/max range)
      if (direction === 'Both' || !direction) {
        if (minDate && value < minDate) {
          return { minDate: { requiredDate: minDate } };
        }
        if (maxDate && value > maxDate) {
          return { maxDate: { requiredDate: maxDate } };
        }
      }

      return null; // No validation errors if date meets all constraints
    };
  }

  /**
   * Returns the appropriate view type for a date picker based on the provided object field type.
   *
   * @param {string} objectFieldType - The type of the object field (e.g., 'Month', 'Year', 'Date and Time').
   * @returns {string} - The view type for the date picker ('month', 'year', 'datetime', 'date').
   */
  getViewType(objectFieldType: string): any {
    switch (objectFieldType) {
      case 'Month':
        return 'month';
      case 'Year':
        return 'year';
      default:
        return 'date';
    }
  }

  /**
   * Returns the appropriate date format for a date picker based on the provided object field type.
   *
   * @param {string} objectFieldType - The type of the object field (e.g., 'Month', 'Year', 'Date and Time').
   * @returns {string} - The date format for the date picker (e.g., 'MM', 'yyyy', 'yyyy-MM-dd H:mm:ss', 'yyyy-MM-dd').
   */
  getDateFormat(objectFieldType: string): any {
    switch (objectFieldType) {
      case 'Month':
        return 'mm/yy'; // Ensure this aligns with PrimeNG’s format
      case 'Year':
        return 'yy'; // PrimeNG might expect only the year part
      case 'Date and Time':
        return 'dd-mm-yy HH:mm:ss';
      default:
        return 'dd-mm-yy';
    }
  }

  // Method to retrieve and format the minimum date error message
  getFutureDateValidation(fieldName: string): string | null {
    const pastDateError = this.dynamicForm.get(fieldName)?.errors?.['futureDate'];

    if (pastDateError) {
      const formattedDate = this.datePipe.transform(pastDateError.requiredDate, 'dd-MM-yyyy');

      return this.translateService.instant('VALIDATION.FUTURE_DATE_ONLY', {
        date: formattedDate,
      });
    }

    return null;
  }

  // Method to retrieve and format the maximum date error message
  getPastDateValidation(fieldName: string): string | null {
    const maxDateError = this.dynamicForm.get(fieldName)?.errors?.['pastDate'];

    if (maxDateError) {
      const formattedDate = this.datePipe.transform(maxDateError.requiredDate, 'dd-MM-yyyy');

      return this.translateService.instant('VALIDATION.PAST_DATE_ONLY', {
        date: formattedDate,
      });
    }

    return null;
  }

  // Define arrays to store metricObjectFields and metricVersionObjectFields
  simulateDataAsPerAPI(value: any): any {
    let field: any;
    const newMetricObjectFields: any[] = [];
    const newmetricVersionObjectFields: any[] = [];

    // Iterate through the form values
    Object.keys(value).forEach(key => {
      const controlValue = value[key];

      // Find the corresponding udfData based on fieldName
      const udfData = this.udfInfo.find((data: any) => data.fieldName === key);

      // If udfData is not found, skip this key
      if (!udfData) {
        return;
      }

      // Create metric object field as it will be mapped as per parent from UDf
      // merge if objectfieldDescription id is available - this happen in case of edit
      if (udfData.objectFieldDescrId) {
        field = {
          objectFieldDescrId: udfData.objectFieldDescrId,
          id: udfData.id,
        };
      } else {
        field = {
          objectFieldDescrId: udfData.id,
        };
      }

      // Set the value based on objectFieldType using switch statement
      switch (udfData.objectFieldType) {
        case 'Value Set':
          // For Value Set, use valueSetRowId instead of value
          field.valueSetKey = controlValue;
          break;
        case 'Date and Time':
          field.value = this.datePipe.transform(controlValue, 'yyyy-MM-dd H:mm:ss');
          break;
        case 'Date':
        case 'Month':
        case 'Year':
          field.value = this.isValidDate(controlValue)
            ? this.datePipe.transform(controlValue, 'yyyy-MM-dd')
            : controlValue;
          break;
        default:
          // For other cases, set the value directly
          field.value = controlValue;
          break;
      }

      // Push the field to the corresponding array based on parentType
      if (
        udfData.parentType === 'MetricCatalog' ||
        udfData.parentType === 'Metric' ||
        udfData.parentType === 'Hierarchy' ||
        udfData.parentType === 'Contract'
      ) {
        newMetricObjectFields.push(field);
      } else if (udfData.parentType === 'MetricVersion') {
        newmetricVersionObjectFields.push(field);
      }
    });

    this.metricObjectFields = [...newMetricObjectFields];

    // merge newMetricObjectFields with exisiting metriicObjectFields
    this.metricVersionObjectFields = [...newmetricVersionObjectFields];

    // Construct the payload with metricObjectFields and metricVersionObjectFields
    const payload = {
      metricObjectFields: [...this.metricObjectFields],
      metricVersionObjectFields: [...this.metricVersionObjectFields],
    };

    this.loading = false;
    this.formDataChanged.emit(payload);
  }

  /** Upload  */
  uploadReportKPI(event: any): void {
    this.uploadedFiles = [];
    this.errorMsgList = [];
    const fileList: FileList = event.files;

    for (let i = 0; i < fileList.length; i += 1) {
      this.uploadedFiles.push(fileList[i]);
      // this.isImageOrCSV(event.files);
    }
  }

  isValidDate(dateString: string): boolean {
    const date = new Date(dateString);

    // eslint-disable-next-line no-restricted-globals
    return !isNaN(date.getTime());
  }

  getCustomChooseLabel(): string {
    return this.translateService.instant('MODULES.REPORTS.UPLOAD.UPLOAD_CSV');
  }

  getInvalidFileTypeMessageSummary(): string {
    return '';
  }

  getInvalidFileTypeMessageDetail(): string {
    return this.translateService.instant('MODULES.REPORTS.UPLOAD.FILE_FORMAT');
  }

  clearUploadValue(event: any): void {
    if (event) {
      this.uploadedFiles = [];
      this.errorMsgList = [];
    }
  }

  /** End of Upload  */

  emitFormData(): void {
    this.formDataChanged.emit(this.dynamicForm.value);
  }
}
