import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ReportPermissionService {
  hasUploadPermission(): boolean {
    const permissions: any = sessionStorage.getItem('permissions');

    if (permissions?.includes('Uploads Execute')) {
      return true;
    }

    return false;
  }

  hasReportDesignerPermission(): boolean {
    const permissions: any = sessionStorage.getItem('permissions');

    if (permissions?.includes('Reports Template Add')) {
      return true;
    }

    return false;
  }
}
