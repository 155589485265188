import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';

const routes = {
  valueSetRoute: `${environment.SLMUrl}/valueset`,
  calendarIdRoute: `${environment.SLMUrl}/calendar/groupId`,
};

@Injectable({
  providedIn: 'root',
})
export class UdfService {
  viewUDFData = new BehaviorSubject<any>('');

  viewUDFVersion = new BehaviorSubject<any>('');

  constructor(private httpClient: HttpClient) {}

  getUDFType(data: any): void {
    this.viewUDFData.next(data);
  }

  /** Initiate Api trigger to get valueset id */
  getValueSet(id: number | string): any {
    return this.httpClient.get(`${routes.valueSetRoute}/${id}`);
  }

  /** Initiate Api trigger to get Calendar by id */
  getCalendar(id: number | string): any {
    return this.httpClient.get(`${routes.calendarIdRoute}/${id}`);
  }
}
