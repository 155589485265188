<div class="right-sidebar-header">
  <h4 class="fw-bold" translate>MODULES.CREATE_METRIC.ADD_METRIC_TEMPLATE</h4>
</div>

<form [formGroup]="createMetricForm">
  <div class="right-sidebar-content">
    <div class="padding-left-right">
      <div class="grid">
        <div class="col-8">
          <div class="grid">
            <div class="col-6">
              <label class="fw-bold mb-2" for="id">{{
                'MODULES.VIEW_METRICS.METRIC_ID' | translate
              }}</label>

              <span class="w-100">
                <input
                  class="w-100 height-40 p-inputtext-sm"
                  placeholder="{{ 'PLACEHOLDER.TYPE_HERE' | translate }}"
                  type="text"
                  pInputText
                  formControlName="id"
                />
              </span>
              <div *ngIf="createMetricForm.get('id')?.touched">
                <small
                  *ngIf="createMetricForm.get('id')?.errors?.['required']"
                  class="text-danger"
                >
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </small>
                <small
                  *ngIf="createMetricForm.get('id')?.errors?.['maxlength']"
                  class="text-danger"
                >
                  {{
                    'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 512}'
                  }}
                </small>
              </div>
            </div>
            <div class="col-6">
              <label class="fw-bold mb-2 required" for="name">{{
                'MODULES.CREATE_METRIC.METRIC_NAME' | translate
              }}</label>

              <span class="w-100">
                <input
                  class="w-100 height-40 p-inputtext-sm"
                  placeholder="{{ 'PLACEHOLDER.TYPE_HERE' | translate }}"
                  type="text"
                  pInputText
                  formControlName="name"
                />
              </span>

              <div *ngIf="createMetricForm.get('name')?.touched">
                <small
                  *ngIf="createMetricForm.get('name')?.errors?.['required']"
                  class="text-danger"
                >
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </small>
                <small
                  *ngIf="createMetricForm.get('name')?.errors?.['maxlength']"
                  class="text-danger"
                >
                  {{
                    'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 250}'
                  }}
                </small>
              </div>
            </div>
          </div>

          <div class="grid mt-1">
            <div class="col-6">
              <label class="fw-bold mb-2 required" for="businessService">{{
                'MODULES.CREATE_METRIC.OFFERING' | translate
              }}</label>

              <span class="">
                <input
                  class="w-100 height-40 p-inputtext-sm"
                  placeholder="{{ 'PLACEHOLDER.SELECT' | translate }}"
                  type="text"
                  pInputText
                  formControlName="offeringId"
                  [attr.disabled]="true"
                />
              </span>
              <div *ngIf="createMetricForm.get('businessService')?.touched">
                <small
                  *ngIf="createMetricForm.get('businessService')?.errors?.['required']"
                  class="text-danger"
                >
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </small>
              </div>
            </div>

            <div class="col-6">
              <label class="fw-bold mb-2 required" for="metricConnector">
                {{ 'MODULES.METRICS.METRIC_CONNECTOR' | translate }}
              </label>

              <span class="w-100">
                <p-autoComplete
                  styleClass="w-100"
                  inputStyleClass="w-100 height-40"
                  placeholder="{{ 'PLACEHOLDER.TYPE_HERE' | translate }}"
                  [suggestions]="connectorList"
                  field="name"
                  (completeMethod)="metricConnectorData($event)"
                  [showEmptyMessage]="true"
                  emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}"
                  [minLength]="3"
                  [forceSelection]="true"
                  [showClear]="true"
                  formControlName="metricConnector"
                  (onSelect)="onSelectConnector()"
                  (onClear)="onResetConnector()"
                >
                </p-autoComplete>
              </span>
              <small class="font-12">
                <b>{{ 'VALIDATION.AUTOCOMPLETE_MINLENGTH' | translate }}</b>
              </small>

              <div *ngIf="createMetricForm.get('metricConnector')?.touched">
                <small
                  class="text-danger"
                  *ngIf="createMetricForm.get('metricConnector')?.errors?.['required']"
                >
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </small>
              </div>
            </div>
          </div>
        </div>

        <!-- Description -->
        <div class="col-4">
          <label class="fw-bold mb-2" for="description">{{
            'MODULES.VIEW_METRICS.DESCRIPTION' | translate
          }}</label>

          <span class="w-100">
            <textarea
              class="w-100 textarea-height"
              [autoResize]="true"
              placeholder="{{ 'PLACEHOLDER.TYPE_HERE' | translate }}"
              pInputTextarea
              formControlName="description"
            >
            </textarea>
          </span>

          <div *ngIf="createMetricForm.get('description')?.touched">
            <small
              *ngIf="createMetricForm.get('description')?.errors?.['maxlength']"
              class="text-danger"
            >
              {{
                'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 4000}'
              }}
            </small>
          </div>
        </div>

        <!-- Paramter Section -->
        <div
          class="parameter-container"
          *ngIf="isSelectedConnector && metricConnectorParamList?.length > 0"
        >
          <h5 class="mb-4">
            {{ 'MODULES.VIEW_METRICS.PARAMETER' | translate }}
            <span class="ps-2 fw-normal"
              >({{
                this.createMetricForm.get('metricConnector')?.value?.name
              }})</span
            >
          </h5>
          <app-connector-parameters
            [metricParamsInfo]="metricConnectorParamList"
            [metricType]="'Catalog'"
            (formDataChanged)="onParameterFormSubmit($event)"
          >
          </app-connector-parameters>
        </div>
      </div>

      <p-divider></p-divider>

      <div class="grid mt-0">
        <div class="col-4">
          <label class="fw-bold mb-2 required" for="statement">{{
            'MODULES.CREATE_METRIC.STATEMENT' | translate
          }}</label>
          <span class="w-100">
            <textarea
              pInputTextarea
              class="w-100 textarea-height"
              [autoResize]="true"
              placeholder="{{ 'PLACEHOLDER.TYPE_HERE' | translate }}"
              formControlName="statement"
            ></textarea>
          </span>

          <div *ngIf="createMetricForm.get('statement')?.touched">
            <small
              *ngIf="createMetricForm.get('statement')?.errors?.['required']"
              class="text-danger"
            >
              {{ 'VALIDATION.REQUIRED' | translate }}
            </small>
            <small
              *ngIf="createMetricForm.get('statement')?.errors?.['maxlength']"
              class="text-danger"
            >
              {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 250}' }}
            </small>
          </div>
        </div>
        <div class="col-8 mt-0">
          <div class="grid">
            <div class="col-6">
              <label class="fw-bold mb-2 required" for="target">{{
                'MODULES.CREATE_METRIC.CONDITION' | translate
              }}</label>

              <span class="w-100">
                <p-dropdown
                  styleClass="w-100 height-40"
                  [options]="conditionList"
                  formControlName="condition"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="{{ 'PLACEHOLDER.SELECT' | translate }}"
                  [showClear]="true"
                  [autoDisplayFirst]="false"
                  emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}"
                >
                </p-dropdown>
              </span>

              <div *ngIf="createMetricForm.get('condition')?.touched">
                <small
                  *ngIf="createMetricForm.get('condition')?.errors?.['required']"
                  class="text-danger"
                >
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </small>
              </div>
            </div>

            <div class="col-6">
              <label class="fw-bold mb-2 required" for="period">{{
                'MODULES.CREATE_METRIC.PERIOD' | translate
              }}</label>
              <span class="w-100">
                <p-dropdown
                  styleClass="w-100 height-40"
                  [options]="periodList"
                  [editable]="true"
                  formControlName="period"
                  optionLabel="name"
                  optionValue="id"
                  [showClear]="true"
                  [autoDisplayFirst]="false"
                  placeholder="{{ 'PLACEHOLDER.SELECT' | translate }}"
                  emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}"
                >
                </p-dropdown>
                <!-- <p-dropdown
                styleClass="w-100 height-40"
                [options]="conditionList"
                formControlName="condition"
                optionLabel="name"
                optionValue="id"
                placeholder="{{ 'PLACEHOLDER.SELECT' | translate }}"
                [showClear]="true"
                [autoDisplayFirst]="false"
                emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}"
              > -->
              </span>
              <div *ngIf="createMetricForm.get('period')?.touched">
                <small
                  *ngIf="createMetricForm.get('period')?.errors?.['required']"
                  class="text-danger"
                >
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </small>
              </div>
            </div>
          </div>
          <div class="grid mt-1">
            <div class="col-6">
              <label class="fw-bold mb-2 required" for="target">{{
                'MODULES.CREATE_METRIC.FORMAT' | translate
              }}</label>
              <span class="w-100">
                <p-dropdown
                  styleClass="w-100 height-40"
                  [options]="formatList"
                  formControlName="targetFormat"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="{{ 'PLACEHOLDER.SELECT' | translate }}"
                  [editable]="true"
                  emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}"
                  (onChange)="updateSubFormat()"
                  optionDisabled="inactive"
                >
                </p-dropdown>

                <div *ngIf="createMetricForm.get('targetFormat')?.touched">
                  <small
                    class="text-danger"
                    *ngIf="createMetricForm.get('targetFormat')?.errors?.['required']"
                  >
                    {{ 'VALIDATION.REQUIRED' | translate }}
                  </small>
                </div>
              </span>
            </div>
            <div class="col-6">
              <label class="fw-bold mb-2 required" for="target">{{
                'MODULES.CREATE_METRIC.SUB_FORMAT' | translate
              }}</label>
              <span class="w-100">
                <p-dropdown
                  styleClass="w-100 height-40"
                  [options]="subFormatList"
                  formControlName="subTargetFormat"
                  optionLabel="name"
                  optionValue="id"
                  [disabled]="!createMetricForm.get('targetFormat')?.value"
                  placeholder="{{ 'PLACEHOLDER.SELECT' | translate }}"
                  emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}"
                >
                </p-dropdown>
              </span>
              <div *ngIf="createMetricForm.get('subTargetFormat')?.touched">
                <small
                  *ngIf="createMetricForm.get('subTargetFormat')?.errors?.['required']"
                  class="text-danger"
                >
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- UDF Metric -->
      <ng-container *ngIf="UDFList && UDFList.length > 0">
        <p-divider></p-divider>
        <h5 class="pt-2">
          {{ 'MODULES.CREATE_METRIC.UDF_METRIC' | translate }}
        </h5>
        <app-udf
          [udfInfo]="UDFList"
          (formDataChanged)="onFormDataChanged($event)"
        ></app-udf>
      </ng-container>
    </div>

    <!-- Version Details -->
    <div class="mt-4">
      <h4 translate class="right-sidebar-header fw-bold py-3">
        MODULES.CREATE_METRIC.VERSION_DETAILS
      </h4>
    </div>

    <div class="padding-left-right">
      <div class="grid">
        <div class="col-8 mt-0">
          <div class="grid">
            <!-- version name -->
            <div class="col-4">
              <label class="fw-bold mb-2" for="versionName">{{
                'MODULES.CREATE_METRIC.VERSION_NAME' | translate
              }}</label>
              <span class="">
                <input
                  class="w-100 height-40 p-inputtext-sm"
                  placeholder="{{ 'PLACEHOLDER.TYPE_HERE' | translate }}"
                  type="text"
                  pInputText
                  formControlName="versionName"
                />
              </span>
              <div *ngIf="createMetricForm.get('versionName')?.touched">
                <small
                  *ngIf="createMetricForm.get('versionName')?.errors?.['maxlength']"
                  class="text-danger"
                >
                  {{
                    'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 250}'
                  }}
                </small>
              </div>
            </div>

            <!-- target -->
            <div class="col-4">
              <label class="fw-bold mb-2" for="target">{{
                'MODULES.VIEW_METRICS.TARGET' | translate
              }}</label>
              <span class="w-100">
                <p-inputNumber
                  mode="decimal"
                  placeholder="{{ 'PLACEHOLDER.TYPE_HERE' | translate }}"
                  [minFractionDigits]="1"
                  formControlName="target"
                  (onBlur)="getTagetValue()"
                ></p-inputNumber>
              </span>
              <div *ngIf="createMetricForm.get('target')?.touched">
                <small
                  *ngIf="createMetricForm.get('target')?.errors?.['required']"
                  class="text-danger"
                >
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </small>
              </div>
            </div>

            <!-- expected -->
            <div class="col-4">
              <label class="fw-bold mb-2" for="expected">{{
                'MODULES.VIEW_METRICS.EXPECTED' | translate
              }}</label>
              <span class="w-100">
                <p-inputNumber
                  mode="decimal"
                  placeholder="{{ 'PLACEHOLDER.TYPE_HERE' | translate }}"
                  [minFractionDigits]="1"
                  formControlName="expected"
                ></p-inputNumber>
              </span>
              <div *ngIf="createMetricForm.get('expected')?.touched">
                <small
                  *ngIf="createMetricForm.get('expected')?.errors?.['required']"
                  class="text-danger"
                >
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </small>
              </div>
            </div>
          </div>
          <div class="grid mt-1">
            <!-- start date -->
            <div class="col-6">
              <label class="fw-bold mb-2" for="startDate">{{
                'MODULES.VIEW_METRICS.START_DATE' | translate
              }}</label>
              <span class="w-100">
                <p-calendar
                  styleClass="w-100 height-40"
                  placeholder="{{ 'PLACEHOLDER.SELECT_DATE' | translate }}"
                  formControlName="startDate"
                  [showIcon]="true"
                  dateFormat="dd-mm-yy"
                  [minDate]="minStartDate"
                  (onSelect)="onSelectStartDate($event)"
                  [readonlyInput]="true"
                  appendTo="body"
                >
                </p-calendar>
              </span>
              <div *ngIf="createMetricForm.get('startDate')?.touched">
                <small
                  *ngIf="createMetricForm.get('startDate')?.errors?.['required']"
                  class="text-danger"
                >
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </small>
              </div>
            </div>
            <!-- end date -->
            <div class="col-6">
              <label class="fw-bold mb-2" for="endDate">{{
                'MODULES.VIEW_METRICS.END_DATE' | translate
              }}</label>
              <span class="w-100">
                <p-calendar
                  styleClass="w-100 height-40"
                  placeholder="{{ 'PLACEHOLDER.SELECT_DATE' | translate }}"
                  formControlName="endDate"
                  [showIcon]="true"
                  [minDate]="minEndDate"
                  [maxDate]="maxEndDate"
                  dateFormat="dd-mm-yy"
                  [readonlyInput]="true"
                  [defaultDate]="minEndDate"
                  appendTo="body"
                >
                </p-calendar>
              </span>
              <div *ngIf="createMetricForm.get('endDate')?.touched">
                <small
                  *ngIf="createMetricForm.get('endDate')?.errors?.['required']"
                  class="text-danger"
                >
                  {{ 'VALIDATION.REQUIRED' | translate }}
                </small>
              </div>
            </div>
          </div>
        </div>

        <!-- version statement -->
        <div class="col-4">
          <label class="fw-bold mb-2" for="versionStatement">{{
            'MODULES.CONTRACTS.NOTES' | translate
          }}</label>
          <span class="w-100">
            <textarea
              pInputTextarea
              class="w-100 textarea-height"
              [autoResize]="true"
              placeholder="{{ 'PLACEHOLDER.TYPE_HERE' | translate }}"
              formControlName="versionStatement"
            ></textarea>
          </span>
          <div *ngIf="createMetricForm.get('versionStatement')?.touched">
            <small
              *ngIf="createMetricForm.get('versionStatement')?.errors?.['maxlength']"
              class="text-danger"
            >
              {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 250}' }}
            </small>
          </div>
        </div>
      </div>
    </div>
    <!-- api error msg block -->
    <app-error-block></app-error-block>
  </div>
  <div class="right-sidebar-footer">
    <div class="w-100 d-flex">
      <!-- Left side: Cancel button -->
      <button
        pButton
        type="button"
        class="p-button-outlined me-auto"
        label="{{ 'BUTTONS.CANCEL' | translate }}"
        (click)="resetForm()"
      >
        <img src="assets/svg_icons/close-circle-blue.svg" />
      </button>

      <!-- Right side: Save and Publish buttons -->
      <button
        pButton
        type="submit"
        class="p-button-draft me-2"
        label="{{ 'MODULES.CREATE_METRIC.SAVE_TO_DRAFT' | translate }}"
        (click)="saveNewMetric()"
      >
        <img src="assets/svg_icons/save.svg" />
      </button>

      <button
        pButton
        type="submit"
        class="p-button-publish"
        label="{{ 'MODULES.CREATE_METRIC.PUBLISH' | translate }}"
        (click)="cofirmPublish($event)"
      >
        <img src="assets/svg_icons/publish-white.svg" />
      </button>
    </div>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
