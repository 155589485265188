import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  //   constructor() {}

  toCapitalCase(string: string): string {
    const capsString = string.charAt(0).toUpperCase() + string.slice(1);

    return capsString.split(/(?=[A-Z])/).join(' ');
  }
}
