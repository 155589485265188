<ng-container *ngIf="isEditMetricUDF">
  <div class="right-sidebar-content">
    <!-- General Edit Details -->
    <p-divider styleClass="my-0"></p-divider>
    <h5 class="py-3" translate>MODULES.VIEW_METRICS.GENERAL_TAB</h5>

    <div class="row mb-2">
      <ng-container *ngIf="viewUdfList?.length">
        <app-edit-metric
          [metricGeneralDetails]="viewData"
          (formDataChanged)="onMetricEditFormSubmit($event)"
        ></app-edit-metric>

        <!-- Paramter Section -->
        <div
          class="parameter-container"
          *ngIf="
            viewData?.metricConnector !== 'N/A' &&
            viewMetricParamList?.length > 0
          "
        >
          <h5 class="mb-4">
            {{ 'MODULES.VIEW_METRICS.PARAMETER' | translate }}
            <span class="ps-2 fw-normal">
              ({{ viewData?.metricConnector }})
            </span>
          </h5>
          <app-connector-parameters
            [metricParamsInfo]="viewMetricParamList"
            [metricType]="'Metric'"
            (formDataChanged)="onParameterFormSubmit($event)"
          >
          </app-connector-parameters>
        </div>
      </ng-container>
    </div>

    <!-- CFs Edit Details -->
    <p-divider styleClass="mb-0"></p-divider>
    <h5 class="py-3" translate>MODULES.CREATE_METRIC.UDF_METRIC</h5>

    <div class="row mb-2">
      <ng-container *ngIf="viewUdfList?.length">
        <app-udf
          [udfInfo]="viewUdfList"
          (formDataChanged)="onFormDataChanged($event)"
        ></app-udf>
      </ng-container>
    </div>
  </div>
</ng-container>

<!-- Edit (status-Draft) Version Details -->
<div class="mt-2">
  <h5 class="right-sidebar-header fw-bold">

    <!-- Icon for status -->
    <img class="icon" [src]="getStatusIcon()" *ngIf="viewData?.status" />
    {{ 'MODULES.VIEW_METRICS.VERSION_DETAILS' | translate }}
  </h5>
  <p-divider styleClass="my-0"></p-divider>
</div>

<form [formGroup]="createMetricForm" *ngIf="viewVersionList?.length">
  <div class="px-4">
    <div class="row mt-2">
      <!-- version name -->
      <div class="col-6 col-sm-6">
        <label class="fw-bold mb-2" translate>
          MODULES.VIEW_METRICS.SELECT_VERSION
        </label>
        <ng-container *ngIf="viewVersionList.length">
          <button
            pButton
            type="button"
            class="w-full p-button-outlined custom-btn-outline"
            (click)="op.toggle($event)"
          >
            <div class="w-100 text-center">
              <span class="pe-2"
                >{{
                  selectedVersion
                    ? selectedVersion.versionName
                    : viewVersionList[0].versionName
                }}
              </span>
              <span
                [ngClass]="
                  selectedVersion.statusValue === 'Published'
                    ? 'success-chip'
                    : selectedVersion?.statusValue === 'Draft'
                    ? 'warning-chip'
                    : 'danger-chip'
                "
              >
                {{
                  selectedVersion
                    ? selectedVersion.statusValue
                    : viewVersionList[0].statusValue
                }}
              </span>
            </div>
            <i class="pi pi-angle-down"></i>
          </button>

          <p-overlayPanel
            #op
            [showCloseIcon]="true"
            styleClass="custom-overlay"
          >
            <ng-template pTemplate>
              <div class="mb-3">
                <span class="fw-bold pe-3 ps-2 align-middle" translate
                  >MODULES.VIEW_METRICS.FILTER_BY</span
                >
                <ng-container
                  formArrayName="statusFilter"
                  *ngFor="let check of statusList; let i = index"
                >
                  <p-checkbox
                    [label]="check.label"
                    [binary]="true"
                    class="align-middle px-2"
                    [labelStyleClass]="check.class"
                    [formControlName]="i"
                    (onChange)="applyStatusFilter($event, check.label)"
                  >
                  </p-checkbox>
                </ng-container>
              </div>

              <p-table
                #paginationReset
                styleClass="p-datatable-sm"
                [value]="filteredVersion"
                selectionMode="single"
                [(selection)]="selectedVersion"
                (onRowSelect)="onClosePanel($event)"
                (onRowUnselect)="onClosePanel($event)"
                [paginator]="true"
                [rows]="10"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{{
                  'MODULES.PAGINATION.SHOWING' | translate
                }} {first} {{ 'MODULES.PAGINATION.TO' | translate }}
                                {last} {{
                  'MODULES.PAGINATION.OF' | translate
                }} {{ filteredVersion.length }}
                                {{ 'MODULES.PAGINATION.ENTRIES' | translate }}"
                [scrollHeight]="'calc(100vh - 180px)'"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 110px" translate>
                      MODULES.VIEW_METRICS.VERSION_NAME
                    </th>
                    <th style="width: 90px" translate>
                      MODULES.METRICS.STATUS
                    </th>
                    <th style="width: 100px" translate>
                      MODULES.VIEW_METRICS.START_DATE
                    </th>
                    <th style="width: 100px" translate>
                      MODULES.VIEW_METRICS.END_DATE
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-version>
                  <tr [pSelectableRow]="rowData">
                    <td>{{ version.versionName }}</td>
                    <td>
                      <span
                        [ngClass]="
                          version.statusValue === 'Published'
                            ? 'success-chip'
                            : version.statusValue === 'Draft'
                            ? 'warning-chip'
                            : 'danger-chip'
                        "
                      >
                        {{ version.statusValue }}
                      </span>
                    </td>
                    <td>{{ version.startDate | date : 'yyyy-MM-dd' }}</td>
                    <td>{{ version.endDate | date : 'yyyy-MM-dd' }}</td>
                  </tr>
                </ng-template>
                <!-- no records msg -->
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td class="text-center" [attr.colSpan]="12">
                      {{ 'MODULES.EMPTY_TABLE_MSG' | translate }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </ng-template>
          </p-overlayPanel>
        </ng-container>
      </div>
    </div>
    <div class="row mt-2">
      <!-- input field -->
      <div class="col-4">
        <label class="fw-bold mb-2" for="target">{{
          'MODULES.VIEW_METRICS.VERSION_NAME' | translate
        }}</label>
        <span class="w-100 my-2">
          <input
            class="w-100 height-40 p-inputtext-sm"
            type="text"
            pInputText
            formControlName="versionName"
            placeholder="{{ 'MODULES.VIEW_METRICS.VERSION_NAME' | translate }}"
          />
        </span>

        <div *ngIf="createMetricForm.get('versionName')?.touched">
          <small
            *ngIf="createMetricForm.get('versionName')?.errors?.['maxlength']"
            class="text-danger"
          >
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 250}' }}
          </small>
        </div>
      </div>

      <!-- Target -->
      <div class="col-4">
        <label class="fw-bold mb-2" for="target">{{
          'MODULES.VIEW_METRICS.TARGET' | translate
        }}</label>
        <span class="w-100 my-2">
          <p-inputNumber
            mode="decimal"
            [minFractionDigits]="1"
            formControlName="target"
            placeholder="{{ 'MODULES.VIEW_METRICS.TARGET' | translate }} "
            (onBlur)="getTagetValue()"
          ></p-inputNumber>
        </span>
        <div *ngIf="createMetricForm.get('target')?.touched">
          <small
            *ngIf="createMetricForm.get('target')?.errors?.['required']"
            class="text-danger"
          >
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
      </div>

      <!-- Expected-->
      <div class="col-4">
        <label class="fw-bold mb-2" for="expected">{{
          'MODULES.VIEW_METRICS.EXPECTED' | translate
        }}</label>
        <span class="w-100 my-2">
          <p-inputNumber
            mode="decimal"
            [minFractionDigits]="1"
            placeholder="{{ 'MODULES.VIEW_METRICS.EXPECTED' | translate }}"
            formControlName="expected"
          ></p-inputNumber>
        </span>
        <div *ngIf="createMetricForm.get('expected')?.touched">
          <small
            *ngIf="createMetricForm.get('expected')?.errors?.['required']"
            class="text-danger"
          >
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
      </div>

      <!--Start date-->
      <div class="col-4">
        <label class="fw-bold mb-2" for="startDate">{{
          'MODULES.VIEW_METRICS.START_DATE' | translate
        }}</label>
        <span class="w-100 my-2">
          <p-calendar
            styleClass="w-100 height-40"
            placeholder=" {{ 'MODULES.VIEW_METRICS.START_DATE' | translate }}"
            formControlName="startDate"
            [showIcon]="true"
            dateFormat="dd-mm-yy"
            [minDate]="minStartDate"
            (onSelect)="onSelectStartDate($event)"
            [readonlyInput]="true"
            appendTo="body"
          >
          </p-calendar>
        </span>
        <div *ngIf="createMetricForm.get('startDate')?.touched">
          <small
            *ngIf="createMetricForm.get('startDate')?.errors?.['required']"
            class="text-danger"
          >
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
      </div>

      <!--End date-->
      <div class="col-4">
        <label class="fw-bold mb-2" for="endDate">{{
          'MODULES.VIEW_METRICS.END_DATE' | translate
        }}</label>
        <span class="w-100 my-2">
          <p-calendar
            styleClass="w-100 height-40"
            placeholder="{{ 'MODULES.VIEW_METRICS.END_DATE' | translate }}"
            formControlName="endDate"
            [showIcon]="true"
            [minDate]="minEndDate"
            [maxDate]="maxEndDate"
            dateFormat="dd-mm-yy"
            [readonlyInput]="true"
            [defaultDate]="minEndDate"
            appendTo="body"
          >
          </p-calendar>
        </span>
        <div *ngIf="createMetricForm.get('endDate')?.touched">
          <small
            *ngIf="createMetricForm.get('endDate')?.errors?.['required']"
            class="text-danger"
          >
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
      </div>

      <!-- version statement -->
      <div class="col-4">
        <label class="fw-bold mb-2" for="metricVersionStatement">{{
          'MODULES.CONTRACTS.NOTES' | translate
        }}</label>
        <span class="w-100 my-2">
          <textarea
            class="w-100 height-40"
            pInputTextarea
            placeholder="{{ 'MODULES.CONTRACTS.NOTES' | translate }}"
            formControlName="metricVersionStatement"
          ></textarea>
        </span>
        <div *ngIf="createMetricForm.get('metricVersionStatement')?.touched">
          <small
            *ngIf="createMetricForm.get('metricVersionStatement')?.errors?.['maxlength']"
            class="text-danger"
          >
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 250}' }}
          </small>
        </div>
      </div>
    </div>
  </div>

  <!--UDF Version-->
  <ng-container *ngIf="UDFVersionList?.length">
    <div class="right-sidebar-content">
      <p-divider styleClass="mb-0"></p-divider>
      <h5 translate class="py-3">MODULES.VIEW_METRICS.UDF_VERSION</h5>

      <div class="row mb-2">
        <ng-container *ngIf="UDFVersionList?.length">
          <app-udf
            [udfInfo]="UDFVersionList"
            (formDataChanged)="onFormDataChanged($event)"
          ></app-udf>
        </ng-container>
      </div>

      <ng-container *ngIf="UDFVersionList && !UDFVersionList.length">
        <div class="row mt-2">
          <div class="col-4 mb-1" *ngFor="let item of [].constructor(6)">
            <p-skeleton></p-skeleton>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <div class="px-4">
    <!-- api error msg block -->
    <app-error-block></app-error-block>

    <!-- btn section -->
    <div class="row mt-2">
      <div class="col-4 col-sm-4">
        <button
          pButton
          type="button"
          label="{{ 'MODULES.DISTRIBUTIONS.CANCEL' | translate }}"
          class="p-button-outlined"
          style="width: 110px"
          (click)="closeViewMetric()"
        ></button>
      </div>
      <div
        class="col-4 col-sm-4 text-center"
        *ngIf="selectedVersion.statusValue === 'Draft'"
      >
        <button
          pButton
          type="submit"
          label="{{ 'MODULES.VIEW_METRICS.SAVE' | translate }}"
          [disabled]="!metricPermission.hasEditMetricVersion()"
          class="p-button"
          style="width: 110px"
          (click)="saveUpdatedVersion(viewData.id)"
        ></button>
      </div>
      <div
        class="col-4 col-sm-4 text-right"
        *ngIf="selectedVersion.statusValue === 'Draft'"
      >
        <button
          pButton
          type="submit"
          label="{{ 'MODULES.CREATE_METRIC.PUBLISH' | translate }}"
          [disabled]="!metricPermission.hasPublishMetricVersion()"
          class="p-button"
          style="width: 110px"
          (click)="cofirmPublish($event)"
        ></button>
      </div>
      <div
        class="col-8 col-sm-8 text-right"
        *ngIf="selectedVersion.statusValue === 'Published'"
      >
        <button
          pButton
          type="submit"
          label="{{ 'MODULES.CREATE_METRIC.REVOKE' | translate }}"
          [disabled]="!metricPermission.hasRevokeMetricVersion()"
          class="p-button"
          style="width: 110px"
          (click)="cofirmRevoke($event)"
        ></button>
      </div>
    </div>
  </div>
</form>

<ng-container *ngIf="!viewVersionList?.length">
  <div class="row mt-2 px-4">
    <div class="col-4 mb-1" *ngFor="let item of [].constructor(6)">
      <p-skeleton></p-skeleton>
    </div>
  </div>
</ng-container>

<p-confirmPopup></p-confirmPopup>
