<form [formGroup]="editMetricForm">
  <div class="grid my-2">
    <!-- metric id -->
    <div class="col-6">
      <label class="fw-bold mb-2" for="id">{{
        'MODULES.VIEW_METRICS.METRIC_ID' | translate
      }}</label>
      <span class="w-100">
        <input
          class="w-100 height-40 p-inputtext-sm"
          placeholder="{{ 'PLACEHOLDER.TYPE_HERE' | translate }}"
          type="text"
          pInputText
          formControlName="id"
        />
      </span>
      <div *ngIf="editMetricForm.get('id')?.touched">
        <small
          *ngIf="editMetricForm.get('id')?.errors?.['required']"
          class="text-danger"
        >
          {{ 'VALIDATION.REQUIRED' | translate }}
        </small>

        <small
          *ngIf="editMetricForm.get('id')?.errors?.['maxlength']"
          class="text-danger"
        >
          {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 512}' }}
        </small>
      </div>
    </div>

    <!-- metric name -->
    <div class="col-6">
      <label class="fw-bold mb-2 required" for="name">{{
        'MODULES.CREATE_METRIC.METRIC_NAME' | translate
      }}</label>
      <span class="w-100 mt-2 mb-1">
        <input
          class="w-100 height-40 p-inputtext-sm"
          type="text"
          placeholder="{{ 'PLACEHOLDER.TYPE_HERE' | translate }}"
          pInputText
          formControlName="name"
        />
      </span>
      <div *ngIf="editMetricForm.get('name')?.touched">
        <small
          *ngIf="editMetricForm.get('name')?.errors?.['required']"
          class="text-danger"
        >
          {{ 'VALIDATION.REQUIRED' | translate }}
        </small>
        <small
          *ngIf="editMetricForm.get('name')?.errors?.['maxlength']"
          class="text-danger"
        >
          {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 250}' }}
        </small>
      </div>
    </div>

    <!-- metric connector -->
    <!-- <div class="col-4">
      <label class="fw-bold mb-2 required" for="metricConnector">
        {{ 'MODULES.METRICS.METRIC_CONNECTOR' | translate }}
      </label>
      <span class="w-100 mt-1">
        <p-autoComplete styleClass="w-100" inputStyleClass="w-100 height-40"
          placeholder="{{ 'PLACEHOLDER.TYPE_HERE' | translate }}" [suggestions]="connectorList" field="name"
          (completeMethod)="metricConnectorData($event, false)" [showEmptyMessage]="true"
          emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}" [minLength]="3" [forceSelection]="true"
          [showClear]="true" formControlName="metricConnector" (onSelect)="onSelectConnector()"
          (onClear)="onResetConnector()">
        </p-autoComplete>
      </span>
      <small class="font-12">
        <b>{{ 'VALIDATION.AUTOCOMPLETE_MINLENGTH' | translate }}</b>
      </small>

      <div *ngIf="editMetricForm.get('metricConnector')?.touched">
        <small class="text-danger" *ngIf="editMetricForm.get('metricConnector')?.errors?.['required']">
          {{ 'VALIDATION.REQUIRED' | translate }}
        </small>
      </div>
    </div> -->

    <!-- Paramter Section -->
    <!-- <div class="parameter-container" *ngIf="viewMetricParamList?.length > 0">
      <h5 class="mb-4">
        {{ 'MODULES.VIEW_METRICS.PARAMETER' | translate }}
        <span class="ps-2 fw-normal">
          ({{ this.metricGeneralDetails?.metricConnector }})
        </span>
      </h5>
      <app-connector-parameters [metricParamsInfo]="viewMetricParamList" [metricType]="'Metric'"
        (formDataChanged)="onParameterFormSubmit($event)">
      </app-connector-parameters>
    </div> -->
  </div>
</form>
