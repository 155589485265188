<div class="container-fluid">
  <div class="row">
    <div class="col-6">
      <button
        pButton
        type="button"
        label="{{ 'MODULES.METRIC_CONNECTOR.ADD' | translate }}"
        disabled="true"
        class="p-button"
      ></button>
    </div>
    <div class="col-6 text-right">
      <button
        pButton
        type="button"
        class="p-button"
        label="{{ 'MODULES.METRICS.CLEAR_FILTER' | translate }}"
        (click)="clearGrid()"
        [disabled]="!haveFilters"
      ></button>
    </div>
  </div>

  <div class="row">
    <ng-container *ngIf="headers && headers.length > 0">
      <ag-grid-angular
        class="ag-theme-balham custom-ag-table"
        [columnDefs]="headers"
        [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)"
        (sortChanged)="onSortChange()"
        (filterModified)="onFilterModified($event)"
        #agGrid
      >
      </ag-grid-angular>
    </ng-container>
  </div>

  <!-- view metric connector details -->
  <p-sidebar
    [(visible)]="isConnectorsViewEnabled"
    position="right"
    (onHide)="close()"
    styleClass="p-sidebar-md"
    *ngIf="isConnectorsViewEnabled"
  >
    <app-view-metric-connector
      (closeClicked)="isConnectorsViewEnabled = $event"
    ></app-view-metric-connector>
  </p-sidebar>
</div>
