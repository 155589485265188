import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Subscription } from 'rxjs';

import { ReportService } from '@app/report/services/report.service';
import { UtilsService } from '@app/shared/services/utils.service';

@Component({
  selector: 'app-report-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit, OnDestroy {
  @Output() closeClicked = new EventEmitter<boolean>();

  @ViewChild('fileUploader') fileUploadElement!: FileUpload;

  uploadForm!: FormGroup;

  headerRes: any[] = [];

  kpiDataSubscription!: Subscription;

  errorMsgList: any = [];

  uploadedFiles: File[] = [];

  kpiData: any;

  Object = Object;

  // reportId is the upload ID
  reportId: any;

  progressPerc = 0;

  isFileSubmit = false;

  constructor(
    private formBuilder: FormBuilder,
    private reportService: ReportService,
    private messageService: MessageService,
    private translateService: TranslateService,
    public utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.kpiDataSubscription = this.reportService.viewUploadData.subscribe((res: any) => {
      if (res) {
        this.kpiData = res.data;

        // assigning the upload ID
        this.reportId = res.property.data;
      }
    });

    this.getFormControlsFields();
  }

  getFormControlsFields(): void {
    this.reportService.getUploadHeader(this.reportId).subscribe((res: any) => {
      if (res && res.length) {
        this.headerRes = res;
        const formGroupFields: any = {};

        res.forEach((fieldName: any) => {
          Object.keys(fieldName.fieldName).forEach(() => {
            formGroupFields[fieldName.fieldName.toLowerCase()] = [''];
          });
        });
        this.uploadForm = this.formBuilder.group(formGroupFields);
        this.patchvalue(this.kpiData);
        this.uploadForm.disable();
      } else {
        this.uploadForm = this.formBuilder.group({});
      }
    });
  }

  resetForm(): void {
    this.uploadForm.reset();
    this.isFileSubmit = false;
    this.closeClicked.emit(false);
  }

  patchvalue(data: any): void {
    Object.keys(data).forEach((key: string) => {
      const controlName = key;

      if (this.uploadForm.controls[controlName]) {
        this.uploadForm.patchValue({
          [controlName]: data[key] && data[key]?.value ? data[key]?.value : 'N/A',
        });
      }
    });
  }

  uploadReportKPI(event: any): void {
    this.progressPerc = 0;
    this.uploadedFiles = [];
    this.errorMsgList = [];
    const fileList: FileList = event.files;

    for (let i = 0; i < fileList.length; i += 1) {
      this.uploadedFiles.push(fileList[i]);
      // this.isImageOrCSV(event.files);
    }
  }

  saveReportKPI(): void {
    this.errorMsgList = [];
    if (this.uploadForm.invalid) {
      this.uploadForm.markAllAsTouched();
    } else {
      if (!this.uploadedFiles.length) {
        return this.errorMsgList.push(this.translateService.instant('MODULES.REPORTS.UPLOAD.NO_FILE'));
      }

      // check file format
      const fileExt: any = this.uploadedFiles[0].name.split('.').pop();

      if (!fileExt.match('csv|xlsx|xls')) {
        return this.errorMsgList.push(this.translateService.instant('MODULES.REPORTS.UPLOAD.NO_FILE'));
      }

      const formData = new FormData();

      for (let i = 0; i < this.uploadedFiles.length; i += 1) {
        formData.append('file', this.uploadedFiles[i]);
      }

      let payload: any;

      Object.keys(this.uploadForm.value).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(this.uploadForm.value, key)) {
          // get form field the value to send in API (formControls are mapped with formattedValue)
          const value = `${key}Value`;
          const fieldName = key;

          if (this.uploadForm.controls[fieldName]) {
            const params = { [fieldName]: this.kpiData[value] };

            payload = Object.assign(params, payload);
          }
        }
      });

      // submit button will be disabled
      //   this.messageService.add({
      //     severity: 'info',
      //     summary: 'Info',
      //     detail: this.translateService.instant('MODULES.REPORTS.UPLOAD.UPLOAD_PROGRESS'),
      //   });

      this.isFileSubmit = true;

      this.reportService.uploadKPIData(this.reportId, payload, formData).subscribe(
        (res: HttpEvent<any>) => {
          // upload successful
          if (res.type === HttpEventType.Response) {
            this.uploadedFiles = [];
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: this.translateService.instant('MODULES.REPORTS.UPLOAD.UPLOAD_SUCESS'),
            });

            this.resetForm();
          }

          // calculating file upload progress in percentage
          if (res.type === HttpEventType.UploadProgress) {
            if (res.loaded && res.total) {
              this.progressPerc = Math.round((res.loaded / res.total) * 100);
            }

            // onProgress event will be triggered and display the progress without any changes in template
            this.fileUploadElement.onProgress.emit({ originalEvent: res, progress: this.progressPerc });
          }
        },
        (err: any) => {
          if (err && Array.isArray(err)) {
            const errorList: any = [];

            err.forEach((msg: any) => {
              errorList.push(msg.message);
            });
            this.errorMsgList = errorList;
          }

          // reset the progressbar progress if API fails, enable submit button
          this.progressPerc = 0;
          this.fileUploadElement.onProgress.emit({ originalEvent: err, progress: this.progressPerc });
          this.isFileSubmit = false;
        }
      );
    }
  }

  // onProgess event is to update the progressbar and show the uploading progress
  onUploadProgress(event: any): void {
    this.fileUploadElement.progress = event.progress;

    const progressBarElement = this.fileUploadElement.content.nativeElement.getElementsByTagName('p-progressbar')[0];

    progressBarElement.setAttribute('ng-reflect-show-value', true);
    if (progressBarElement.getAttribute('ng-reflect-show-value') === 'true') {
      this.fileUploadElement.progress = event.progress;
    }
  }

  ngOnDestroy(): void {
    this.kpiDataSubscription.unsubscribe();
  }

  getCustomChooseLabel(): string {
    return this.translateService.instant('MODULES.REPORTS.UPLOAD.UPLOAD_CSV');
  }

  getInvalidFileTypeMessageSummary(): string {
    return '';
  }

  getInvalidFileTypeMessageDetail(): string {
    return this.translateService.instant('MODULES.REPORTS.UPLOAD.FILE_FORMAT');
  }

  isCSV(file: File): boolean {
    return file.type === 'text/csv' || file.name.endsWith('.csv');
  }

  clearUploadValue(event: any): void {
    if (event) {
      this.uploadForm.patchValue({ file: '' });
      this.uploadedFiles = [];
      this.errorMsgList = [];
      this.progressPerc = 0;
    }
  }
}
