import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { combineLatest, filter, Observable, Subject, Subscription, takeUntil } from 'rxjs';

import { CellRendererService } from '@app/core/resources/cell-renderer.service';
import { FilterParamsService } from '@app/core/resources/filter-params.service';
import { FilterTextWhitespacesService } from '@app/core/resources/filter-text-whitespaces.service';
import { FilterTypeService } from '@app/core/resources/filter-type.service';
import { PaginationAggridFlexService } from '@app/core/resources/pagination-aggrid-flex.service';
import { SearchService } from '@app/layout/services/search.service';
import { ActionComponent } from '@app/slm/components/contract/action/action.component';
import { IViewContractData } from '@app/slm/interface/view-contrcat';
import { ContractPermissionService } from '@app/slm/services/contract-permission.service';
import { ContractService } from '@app/slm/services/contract.service';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss'],
})
export class ContractComponent implements OnInit, OnDestroy {
  /** New variable - remove while removing prime ng code */
  @ViewChild('agGrid', { read: ElementRef }) gridElementRef!: ElementRef;

  @Output() closeClicked = new EventEmitter<boolean>();

  contractData: any = [];

  isCreateContract = false;

  isContractviewEnabled = false;

  isDuplicateContrcat = false;

  isCreateversion = false;

  isEditVersionEnabled = false;

  isNewContract = false;

  headers: any = [];

  treeData: any = [];

  haveFilters = true;

  grid!: GridApi;

  gridOptions: GridOptions = {
    alwaysMultiSort: true,
    headerHeight: 40,
    groupHeaderHeight: 40,
    rowHeight: 40,
    suppressContextMenu: true,
    overlayNoRowsTemplate: '<span class="ag-overlay-no-rows-center">No records found</span>',
    defaultColDef: { resizable: true },
    autoGroupColumnDef: { minWidth: 250, resizable: true },
    pagination: true,
    paginationPageSize: 50,
    paginationPageSizeSelector: [50, 100, 200],
    maxBlocksInCache: 0,
    cacheBlockSize: 50,
    serverSidePivotResultFieldSeparator: '_',
    pivotMode: false,
    rowModelType: 'serverSide',
    onFirstDataRendered(params) {
      params.api.setGridOption('defaultColDef', { autoHeight: true });
    },
    getMainMenuItems: () => {
      return this.getMainMenuItems();
    }, // custom main column header menu
  };

  selected: any = [];

  // sidebar

  isUpload = false;

  isAddEnabled = false;

  pivotMode = false;

  viewContrcatData!: IViewContractData;

  use60Percent!: boolean;

  routeFilters: any = null; // For Global Seach handling

  private subscriptions: Subscription = new Subscription();

  private destroy$ = new Subject<void>();

  private gridReady$ = new Subject<boolean>();

  constructor(
    private contractService: ContractService,
    private translateService: TranslateService,
    public contractPermission: ContractPermissionService,
    private filterTypeService: FilterTypeService,
    private filterParams: FilterParamsService,
    private filterTextWhitespacesService: FilterTextWhitespacesService,
    private paginationService: PaginationAggridFlexService,
    private cellRendererService: CellRendererService,
    private searchService: SearchService
  ) {
    this.subscriptions.add(
      this.contractService.isViewOpen$.subscribe((isViewOpen: any) => {
        this.isContractviewEnabled = isViewOpen || false;
      })
    );

    this.subscriptions.add(
      this.contractService.isEditOpen$.subscribe((isOpen: any) => {
        this.isEditVersionEnabled = isOpen || false;
      })
    );

    this.subscriptions.add(
      this.contractService.isAddOpen$.subscribe((isAddOpen: any) => {
        this.isCreateversion = isAddOpen || false;
      })
    );

    this.contractService.createdContract.subscribe((res: any) => {
      if (res) {
        this.isNewContract = res.isNew;
        this.contractData = [];
        this.grid.refreshServerSide();
      }
    });
    this.contractService.getSidebarWidth(true);
    this.contractService.isSidebarWidth$.subscribe((isWidth: boolean) => {
      this.use60Percent = isWidth;
    });
  }

  ngOnInit(): void {
    this.getData();
    combineLatest([
      this.gridReady$, // Emits when the grid is ready
      this.searchService.filters$, // Emits when filters are updated
    ])
      .pipe(
        takeUntil(this.destroy$), // Automatically unsubscribe on destroy
        filter(([gridReady, filters]) => gridReady && filters && filters.length > 0) // Ensuring both conditions are met
      )
      .subscribe(([_, filters]) => {
        // Apply filters and refresh the grid
        this.routeFilters = filters;
        this.refreshGridData(); // Refresh the grid with the new filters
      });
  }

  refreshGridData(): void {
    if (this.grid) {
      // Force grid to re-fetch data using updated routeFilters
      this.grid.setGridOption('serverSideDatasource', this.getGridDataFromAPI());
      this.grid.onFilterChanged(); // Trigger the fetch process
    }
  }

  /**
   * Ag grid API and event handler
   */

  onGridReady(event: any): void {
    this.grid = event.api;
    this.gridReady$.next(true); // Signal that the grid is ready
    this.gridReady$.complete(); // Complete the observable as the grid won't "un-ready"

    // If route filters exist, apply them during initialization
    if (this.routeFilters) {
      const transformedFilters = this.transformRowGroupInfo({ filterConditions: [] });

      this.grid.setFilterModel(transformedFilters.filterConditions);
    }

    this.refreshGridData();
  }

  getData(): void {
    // clear headers before jumping-in
    this.headers = [];
    const headers = this.contractService.getContractHeader() as Observable<any>;

    headers.subscribe((res: any) => {
      this.headers = res.map((item: any) => this.headerFormulate(item));
      // this.grid.setGridOption('serverSideDatasource', this.getGridDataFromAPI());

      const isPivotEnabled: boolean = this.headers.some((header: any) => header.pivot === true);

      this.grid.setGridOption('pivotMode', isPivotEnabled);
      // On the row group or pivoting sidebar ( panel ) will be visible
      const isSidebarPanelVisible = this.headers.some((header: any) => header.rowGroup || header.pivot);

      this.grid.setSideBarVisible(isSidebarPanelVisible);
    });
  }

  /**
   * Formulates a header object with the appropriate structure for use in ag grid.
   *
   * @param {Object} header - The header configuration object containing properties of a column.
   * @param {number} header.id - The unique identifier of the header ( can be used later in API).
   * @param {string} header.fieldName - The field name associated with the header.
   * @param {string} header.field - The field name associated with the header (used if fieldName is not present).
   * @param {number} header.minWidth - The minimum width of the column.
   * @param {string} header.displayName - The display name of the header (optional).
   * @param {string} header.headerName - The display name of the header (used if displayName is not present).
   * @param {boolean} header.sortable - Indicates if the column is sortable.
   * @param {string} header.sortOrder - The sort order of the column (optional).
   * @param {string} header.sortDirection - The direction of the sort (optional).
   * @param {string} header.sort - The sort direction of the column (used if sortOrder is not present).
   * @param {boolean} header.filterable - Indicates if the column is filterable.
   * @param {string} header.fieldType - The type of the field for determining filter type and parameters.
   * @param {boolean} header.groupRowsBy - Indicates if the rows should be grouped by this column.
   * @param {boolean} header.groupColumnsBy - Indicates if the columns should be grouped by this column.
   * @param {boolean} header.enableRowGroup - Indicates if row grouping is enabled for this column.
   * @param {boolean} header.groupColumnsBy - Indicates if pivoting is enabled for this column.
   * @param {boolean} header.enableValue - Indicates if this column can be used as a value column.
   * @param {string} header.aggFunction - The aggregation function for this column (optional).
   * @param {number} header.dataTypeFormatId - The data type format identifier (optional).
   * @param {boolean} header.hide - Indicates if the column should be hidden.
   *
   * @returns {Object} - The formulated header object with the necessary properties for a grid.
   */
  headerFormulate(header: any): any {
    const shouldHide = header.groupRowsBy || header.hidden || header.hide;
    const headerObj: any = {
      id: header.id,
      field: header.fieldName ? header.fieldName.toLowerCase() : header.field.toLowerCase(),
      minWidth: header.minWidth,
      maxWidth: header.maxWidth,
      wrapText: header.textWrapping,
      // autoHeight: header.textWrapping,
      headerName: header.displayName ? header.displayName : header.headerName,
      sortable: header.sortable,
      sort: header.sortOrder ? header.sortDirection.toLowerCase() : header.sort,
      initialSortIndex: header.sortOrder ? header.sortOrder : header.initialSortIndex,
      filter: header.filterable ? this.filterTypeService.getMeFilterType(header.fieldType) : header.filter,
      filterParams: header.filterable ? this.filterParams.getMeFilterParams(header.fieldType) : header.filterParams,
      cellRenderer:
        header.displayName.toLowerCase() === 'action'
          ? ActionComponent
          : (rowData: any) => {
              return this.cellRendererService.reportCellRenderer(rowData);
            },
      enableRowGroup: header.enableRowGroup,
      rowGroup: header.groupRowsBy,
      hide: shouldHide,
      enableValue: header.enableValue,
      aggFunc: header.aggFunction,
      dataTypeFormatId: header.dataTypeFormatId,
      enablePivot: header.enablePivot,
      pivot: header.groupColumnsBy,
    };

    return headerObj;
  }

  getMeSorted(dirtyData: any): any {
    if (dirtyData.length > 0) {
      const sort: any = [];

      for (let i = 0; i <= dirtyData.length - 1; i -= -1) {
        sort.push(`${dirtyData[i].colId},${dirtyData[i].sort}`);
      }

      return sort;
    }

    return '';
  }

  // prevent pagination updates on sorting when no records are available
  onSortChange(): void {
    if (!this.contractData.length) {
      this.paginationService.setLbLastRowOnPage(this.grid, this.gridElementRef, true);
    }
  }

  getMeFiltered(filterData: any, rowGroupInfo: any): any {
    const sqlQueries: any = [];

    Object.keys(filterData).forEach(key => {
      const filterItem = filterData[key];
      const conditions: any = [];

      const appliedFilterData: any = {
        fieldName: key,
        filterType: filterItem.filterType,
      };

      if (filterItem.operator) {
        appliedFilterData.operator = filterItem.operator;

        filterItem.conditions.forEach((item: any) => {
          const filterVal: any = {
            type: item.type,
            start: item.filterType === 'date' ? item.dateFrom : item.filter,
          };

          if (item.type === 'inRange') {
            filterVal.end = item.filterType === 'date' ? item.dateTo : item.filterTo;
          }
          conditions.push(filterVal);
        });
      } else {
        const filterVal: any = {
          type: filterItem.type,
          start: filterItem.filterType === 'date' ? filterItem.dateFrom : filterItem.filter,
        };

        if (filterItem.type === 'inRange') {
          filterVal.end = filterItem.filterType === 'date' ? filterItem.dateTo : filterItem.filterTo;
        }
        conditions.push(filterVal);
      }

      appliedFilterData.conditions = conditions;

      sqlQueries.push(appliedFilterData);
    });

    this.haveFilters =
      (this.routeFilters && this.routeFilters.length > 0) || Object.values(this.grid.getFilterModel()).length > 0; // For search and individual  filters
    const filterConditions = [...sqlQueries]; // Start with search filters
    const payload: any = filterConditions.length ? { filterConditions } : {}; // Preparing the payload for search filters
    const agParamsRequestData: any = this.transformRowGroupInfo(rowGroupInfo); // Transform row group info for additional filters

    //  Merge filters, ensuring search filters take precedence
    if (agParamsRequestData.filterConditions?.length) {
      // Append only additional filters that are not already in search filters
      const additionalFilters = agParamsRequestData.filterConditions.filter(
        (newFilter: any) =>
          !filterConditions.some((existingFilter: any) => existingFilter.fieldName === newFilter.fieldName)
      );

      filterConditions.push(...additionalFilters);
    }

    // Merge the transformed data with search filters
    const mergedPayload = {
      ...agParamsRequestData,
      ...payload,
      filterConditions, // Use the merged filterConditions
    };

    //  Remove unnecessary keys
    delete mergedPayload.startRow;
    delete mergedPayload.endRow;

    return mergedPayload;
  }

  // handling if filter only contains whitespace
  onFilterModified(e: any): void {
    this.filterTextWhitespacesService.textWhitespaceTrim(e, this.grid);
  }

  getGridDataFromAPI(): any {
    return {
      getRows: (agParams: any) => {
        const params: any = {
          offset: agParams.request.startRow,
          limit: agParams.request.endRow - agParams.request.startRow,
          sort: this.getMeSorted(agParams.request.sortModel),
        };

        // updating cache to be in sync with page size
        this.grid.setGridOption('cacheBlockSize', this.grid.paginationGetPageSize());

        this.contractService
          .reportContractData(this.getMeFiltered(agParams.request.filterModel, agParams.request), params)
          .subscribe((res: any) => {
            this.contractData = res.elements;
            if (!res.elements.length) {
              this.grid.showNoRowsOverlay();
            } else {
              this.grid.hideOverlay();
            }

            const tableData: any = res.elements.map((row: any) => {
              const rowDataEntry: any = {};
              let attributes: any = [];

              // Construct row data
              row.reportColumnData.forEach((colData: any) => {
                const fieldName = this.grid.isPivotMode() ? colData.fieldName : colData.fieldName.toLowerCase();

                rowDataEntry[fieldName] = colData.formattedValue; // Store formatted value
                rowDataEntry[`${fieldName}Value`] = colData.value; // Store actual value

                // Pass field identifier in attributes
                if (colData.reportAttributeDtos) {
                  const newAttr = colData.reportAttributeDtos?.map((v: any) => ({
                    ...v,
                    field: colData.fieldName.toLowerCase(),
                  }));

                  attributes = [...attributes, ...newAttr];
                }
                rowDataEntry.attributes = attributes;
              });

              return rowDataEntry;
            });

            agParams.success({
              rowData: tableData,
              rowCount: res.totalElements,
            });

            // auto-resize all columns by default
            // check with grouping and spanning
            agParams.columnApi.autoSizeAllColumns();
            this.routeFilters = []; // Resetting as the search navigation has been completed
            this.searchService.setFilters([]);

            // Workaround to show the actual number of rows for a given page
            this.paginationService.setLbLastRowOnPage(this.grid, this.gridElementRef);
          });
      },
    };
  }

  transformRowGroupInfo(rowGroupInfo: any): any {
    // Create a mapping from fieldName to an object containing id, fieldName, and aggFunc from headers
    const fieldToIdMap = this.headers.reduce((accumulator: any, header: any) => {
      accumulator[header.field] = {
        id: header.id,
        fieldName: header.field,
        aggFunction: header.aggFunc,
        formatId: header.dataTypeFormatId,
      };

      return accumulator; // Return the accumulator object for the next iteration
    }, {});

    // Transform rowGroupCols by mapping each column to its corresponding id and fieldName
    const groupByRow = rowGroupInfo.rowGroupCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      // If mapping exists, replace the id and fieldName, otherwise, return the column unchanged
      return mappedField
        ? { ...col, id: mappedField.id, fieldName: mappedField.fieldName, formatId: mappedField.formatId }
        : col;
    });

    // Transform valueCols by mapping each column to its corresponding id and fieldName, and override aggFunc if available
    const valueCols = rowGroupInfo.valueCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      return mappedField
        ? {
            ...col,
            id: mappedField.id,
            fieldName: mappedField.fieldName,
            aggFunction: mappedField.aggFunc || col.aggFunc,
            formatId: mappedField.formatId,
          }
        : col;
    });

    // Transform pivotCols
    const pivotColumns = rowGroupInfo.pivotCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      return mappedField
        ? { ...col, id: mappedField.id, fieldName: mappedField.fieldName, formatId: mappedField.formatId }
        : col;
    });

    // Transform sortModel
    const sortModel = rowGroupInfo.sortModel.map((sort: any) => {
      const mappedField = fieldToIdMap[sort.colId.toLowerCase()];

      return mappedField ? { ...sort, colId: mappedField.fieldName } : sort;
    });

    // Synchronize filter conditions with routeFilters
    const filterConditions = this.routeFilters
      ? [...(rowGroupInfo.filterConditions || []), ...this.routeFilters] // Merge stored filters
      : rowGroupInfo.filterConditions;

    // Create a new object with transformed properties
    return {
      ...rowGroupInfo,
      groupByRow,
      valueCols,
      pivotColumns,
      sortModel,
      filterConditions,
    };
  }

  clearGrid(): void {
    this.grid.setFilterModel(null);
    this.grid.onFilterChanged();
  }

  onCellClick(event: any): void {
    /**
     * upload will be actionable when the key 'Drill to upload' will be availble in attributes
     * then the right sidebar will open
     */
    if (event.data.attributes.length) {
      event.data.attributes.forEach((property: any) => {
        if (event.column.colId === property.field) {
          if (property.name === 'Drill to Upload') {
            this.isUpload = !this.isUpload;
            // this.reportService.getUploadViewData(event.data);
          }
          if (property.name === 'Drill To Webform Add') {
            this.isAddEnabled = !this.isAddEnabled;
            //    this.reportService.getUploadViewData({ data: event.data, property });
          }
        }
      });
    }
  }

  // custom main column header menu Items
  getMainMenuItems(): [] {
    const customResetCol: any = [
      'pinSubMenu',
      'separator',
      {
        name: 'Reset Columns',
        action: () => {
          this.grid.autoSizeAllColumns();
          this.grid.resetColumnState();
        },
      },
    ];

    return customResetCol;
  }

  close(): void {
    this.isContractviewEnabled = false;
    this.isEditVersionEnabled = false;
    this.isCreateversion = false;
  }

  onCloseViewContrcat(data: { isOpenDuplicateContrcat: boolean; contrcatData?: IViewContractData }): void {
    this.isContractviewEnabled = false;
    if (data.isOpenDuplicateContrcat && data.contrcatData) {
      this.viewContrcatData = data.contrcatData;
      this.isDuplicateContrcat = true;
    }
  }

  ngOnDestroy(): void {
    // Cleaning up all subscriptions
    this.subscriptions.unsubscribe();
    // Signal the end of the component's lifecycle
    this.destroy$.next();
    this.destroy$.complete();
    this.routeFilters = null;
  }
}
