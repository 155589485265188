<div class="right-sidebar-header">
  <h5 class="fw-bold">

    <!-- Icon for status -->
    <img class="icon" [src]="getStatusIcon()" *ngIf="viewData?.status" />

    <ng-container *ngIf="viewData?.name">
      {{ viewData?.name }} {{ 'MODULES.VIEW_METRICS.DETAILS' | translate }}
    </ng-container>

    <div class="flex align-items-center" *ngIf="!viewData?.name">
      <p-skeleton shape="circle" size="1.5rem" styleClass="mr-2"></p-skeleton>
      <p-skeleton width="30rem"></p-skeleton>
    </div>
  </h5>
</div>

<!-- content section -->
<div class="right-sidebar-content">
  <!-- Edit btn -->
  <ng-container *ngIf="!hasPublishedOrRevokedVersion() &&
    !isEditVersionEnabled && !isAddVersionEnabled">
    <div class="w-100 d-flex">
      <div class="col-md-2">
        <button
          pButton
          type="button"
          class="p-button-outlined"
          label="{{ 'MODULES.SLM.EDIT_METRIC' | translate }}"
          (click)="triggerEditMetric(); isEditUDFEnabled = true"
          >
          <img src="assets/svg_icons/edit.svg" class="edit-icon" />
        </button>
      </div>
    </div>
  </ng-container>

  <!-- General details section -->
  <ng-container *ngIf="!isEditUDFEnabled">
    <div class="grid mt-2">
      <!-- Metric ID-->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate>
          MODULES.VIEW_METRICS.CATALOG_ID
        </label>
        <div class="mt-1">
          {{ viewData?.metricNo }}
          <p-skeleton *ngIf="!viewData?.metricNo"></p-skeleton>
        </div>
      </div>

      <!-- Metric name -->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate> MODULES.VIEW_METRICS.NAME </label>
        <div class="mt-1">
          {{ viewData?.name }}
          <p-skeleton *ngIf="!viewData?.name"></p-skeleton>
        </div>
      </div>

      <!--Metric Connector Name-->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate>
          MODULES.METRICS.METRIC_CONNECTOR
        </label>
        <div class="mt-1">
          {{ viewData?.metricConnector }}
          <p-skeleton *ngIf="!viewData?.metricConnector"></p-skeleton>
        </div>
      </div>
    </div>

    <!-- Parameters Details -->
    <ng-container *ngIf="viewMetricParamValues?.length > 0">
      <p-divider styleClass="mt-2 mb-3"></p-divider>
      <div class="grid">
        <div class="col-12 mb-2">
          <h5> {{ 'MODULES.VIEW_METRICS.PARAMETER' | translate }}
            <span class="ps-2 fw-normal">({{ viewData?.metricConnector }})</span>
          </h5>
        </div>

        <!-- Parameters fields -->
        <ng-container *ngFor="let paramFields of viewMetricParamValues">
          <div class="col-4 mb-1">
            <label class="fw-bold text-break" translate>
              {{ paramFields.name }}
            </label>
            <div class="mt-1">
              <span class="text-break">
                {{ paramFields?.value ? paramFields?.value : 'N/A' }}
              </span>
            </div>
          </div>
        </ng-container>

      </div>
      <p-divider styleClass="mt-2 mb-3"></p-divider>
    </ng-container>

    <!-- Paramter Section Skeleton -->
    <ng-container *ngIf="!viewMetricParamValues">
      <div class="row mt-2">
        <div class="col-9 mb-2">
          <p-skeleton></p-skeleton>
        </div>
        <div class="col-4 mb-1" *ngFor="let item of [].constructor(6)">
          <p-skeleton></p-skeleton>
        </div>
      </div>
    </ng-container>

    <!-- Other Metric Details -->
    <div class="grid">
      <!--Offering-->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate> MODULES.VIEW_METRICS.OFFERING</label>
        <div class="mt-1">
          {{ viewData?.businessOffering }}
          <p-skeleton *ngIf="!viewData?.businessOffering"></p-skeleton>
        </div>
      </div>

      <!--Statement-->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate>
          MODULES.VIEW_METRICS.STATEMENT
        </label>
        <div class="mt-1">
          {{ viewData?.statement }}
          <p-skeleton *ngIf="!viewData?.statement"></p-skeleton>
        </div>
      </div>

      <!-- Condition -->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate>
          MODULES.VIEW_METRICS.CONDITION
        </label>
        <div class="mt-1">
          {{ viewData?.conditionName }}
          <p-skeleton *ngIf="!viewData?.conditionName"></p-skeleton>
        </div>
      </div>

      <!-- Format -->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate> MODULES.VIEW_METRICS.FORMAT </label>
        <div class="mt-1">
          {{ viewData?.targetFormatName }}
          <p-skeleton *ngIf="!viewData?.targetFormatName"></p-skeleton>
        </div>
      </div>

      <!-- Sub-format -->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate>
          MODULES.VIEW_METRICS.SUB_FORMAT
        </label>
        <div class="mt-1">
          {{ viewData?.formatName }}
          <p-skeleton *ngIf="!viewData?.formatName"></p-skeleton>
        </div>
      </div>

      <!--Period-->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate> MODULES.VIEW_METRICS.PERIOD </label>
        <div class="mt-1">
          {{ viewData?.periodName }}
          <p-skeleton *ngIf="!viewData?.periodName"></p-skeleton>
        </div>
      </div>

      <!--Description-->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate>
          MODULES.VIEW_METRICS.DESCRIPTION
        </label>
        <div class="mt-1">
          {{ viewData?.description }}
          <p-skeleton *ngIf="!viewData?.description"></p-skeleton>
        </div>
      </div>
    </div>

    <p-divider styleClass="mb-0"></p-divider>

    <!-- UDF Metric Details -->
    <div class="pt-3" *ngIf="viewUdfList && viewUdfList.length > 0">
      <h5 translate>MODULES.VIEW_METRICS.UDF_METRIC</h5>

      <div class="grid mt-2">
        <ng-container *ngFor="let udfField of viewUdfList">
          <div class="col-4 mb-1">
            <label class="fw-bold text-break" translate>
              {{ udfField.name }}</label
            >
            <div class="mt-1">
              <span class="text-break">
                {{ udfField?.formattedValue ? udfField?.formattedValue : 'N/A' }}
              </span>
            </div>
          </div>
        </ng-container>
      </div>

    </div>

    <ng-container *ngIf="!viewUdfList">
      <div class="row mt-2">
        <div class="col-4 mb-1" *ngFor="let item of [].constructor(6)">
          <p-skeleton></p-skeleton>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <!-- Metric Details (display if edit form is open) -->
  <ng-container *ngIf="isEditUDFEnabled">
    <div class="grid">
      <!--Metric Connector Name-->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate>
          MODULES.METRICS.METRIC_CONNECTOR
        </label>
        <div class="mt-1">
          {{ viewData?.metricConnector }}
          <p-skeleton *ngIf="!viewData?.metricConnector"></p-skeleton>
        </div>
      </div>

      <!--Offering-->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate> MODULES.VIEW_METRICS.OFFERING</label>
        <div class="mt-1">
          {{ viewData?.businessOffering }}
          <p-skeleton *ngIf="!viewData?.businessOffering"></p-skeleton>
        </div>
      </div>

      <!--Statement-->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate>
          MODULES.VIEW_METRICS.STATEMENT
        </label>
        <div class="mt-1">
          {{ viewData?.statement }}
          <p-skeleton *ngIf="!viewData?.statement"></p-skeleton>
        </div>
      </div>

      <!-- Condition -->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate>
          MODULES.VIEW_METRICS.CONDITION
        </label>
        <div class="mt-1">
          {{ viewData?.conditionName }}
          <p-skeleton *ngIf="!viewData?.conditionName"></p-skeleton>
        </div>
      </div>

      <!-- Format -->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate> MODULES.VIEW_METRICS.FORMAT </label>
        <div class="mt-1">
          {{ viewData?.targetFormatName }}
          <p-skeleton *ngIf="!viewData?.targetFormatName"></p-skeleton>
        </div>
      </div>

      <!-- Sub-format -->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate>
          MODULES.VIEW_METRICS.SUB_FORMAT
        </label>
        <div class="mt-1">
          {{ viewData?.formatName }}
          <p-skeleton *ngIf="!viewData?.formatName"></p-skeleton>
        </div>
      </div>

      <!--Period-->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate> MODULES.VIEW_METRICS.PERIOD </label>
        <div class="mt-1">
          {{ viewData?.periodName }}
          <p-skeleton *ngIf="!viewData?.periodName"></p-skeleton>
        </div>
      </div>

      <!--Description-->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate>
          MODULES.VIEW_METRICS.DESCRIPTION
        </label>
        <div class="mt-1">
          {{ viewData?.description }}
          <p-skeleton *ngIf="!viewData?.description"></p-skeleton>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!-- Version Details Header -->
<ng-container *ngIf="!isEditVersionEnabled && !isAddVersionEnabled">
  <h5 class="header-color py-3 px-4" translate>
    MODULES.VIEW_METRICS.VERSION_DETAILS
  </h5>
</ng-container>

<ng-container *ngIf="!isEditVersionEnabled && !isAddVersionEnabled">
  <div class="right-sidebar-content">
  <!-- Version Details -->
    <div class="grid">
      <div class="col-6 col-sm-6">
        <label class="fw-bold mb-2" translate>
          MODULES.VIEW_METRICS.SELECT_VERSION
        </label>
        <ng-container *ngIf="viewVersionList.length">
          <button
            pButton
            type="button"
            class="w-full p-button-outlined custom-btn-outline"
            (click)="op.toggle($event)"
          >
            <div class="w-100 text-center">
              <span class="pe-2"
                >{{
                  selectedVersion
                    ? selectedVersion.versionName
                    : viewVersionList[0].versionName
                }}
              </span>
              <span
                [ngClass]="
                  selectedVersion.status === 'Published'
                    ? 'success-chip'
                    : selectedVersion?.status === 'Draft'
                    ? 'warning-chip'
                    : 'danger-chip'
                "
              >
                {{
                  selectedVersion
                    ? selectedVersion.status
                    : viewVersionList[0].status
                }}
              </span>
            </div>
            <i class="pi pi-angle-down"></i>
          </button>

          <p-overlayPanel
            #op
            [showCloseIcon]="true"
            styleClass="custom-overlay"
          >
            <ng-template pTemplate>
              <!-- status filter -->
              <form [formGroup]="createMetricForm">
                <div class="mb-3">
                  <span class="fw-bold pe-3 ps-2 align-middle" translate
                    >MODULES.VIEW_METRICS.FILTER_BY</span
                  >
                  <ng-container
                    formArrayName="statusFilter"
                    *ngFor="let check of statusList; let i = index"
                  >
                    <p-checkbox
                      [label]="check.label"
                      [binary]="true"
                      class="align-middle px-2"
                      [labelStyleClass]="check.class"
                      [formControlName]="i"
                      (onChange)="applyStatusFilter($event, check.label)"
                    >
                    </p-checkbox>
                  </ng-container>
                </div>
              </form>

              <p-table
                #paginationReset
                styleClass="p-datatable-sm"
                [value]="filteredVersion"
                selectionMode="single"
                [(selection)]="selectedVersion"
                (onRowSelect)="onClosePanel($event)"
                (onRowUnselect)="onClosePanel($event)"
                [paginator]="true"
                [rows]="10"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{{
                  'MODULES.PAGINATION.SHOWING' | translate
                }} {first} {{ 'MODULES.PAGINATION.TO' | translate }} {last} {{
                  'MODULES.PAGINATION.OF' | translate
                }} {{ filteredVersion.length }} {{
                  'MODULES.PAGINATION.ENTRIES' | translate
                }}"
                [scrollHeight]="'calc(100vh - 180px)'"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th translate>MODULES.VIEW_METRICS.VERSION_NAME</th>
                    <th style="width: 90px" translate>
                      MODULES.METRICS.STATUS
                    </th>
                    <th style="width: 100px" translate>
                      MODULES.VIEW_METRICS.START_DATE
                    </th>
                    <th style="width: 100px" translate>
                      MODULES.VIEW_METRICS.END_DATE
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-version>
                  <tr [pSelectableRow]="rowData">
                    <td>{{ version.versionName }}</td>
                    <td>
                      <span
                        [ngClass]="
                          version.status === 'Published'
                            ? 'success-chip'
                            : version.status === 'Draft'
                            ? 'warning-chip'
                            : 'danger-chip'
                        "
                      >
                        {{ version.status }}
                      </span>
                    </td>
                    <td>{{ version.startDate | date : 'yyyy-MM-dd' }}</td>
                    <td>{{ version.endDate | date : 'yyyy-MM-dd' }}</td>
                  </tr>
                </ng-template>
                <!-- no records msg -->
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td class="text-center" [attr.colSpan]="4">
                      {{ 'MODULES.EMPTY_TABLE_MSG' | translate }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </ng-template>
          </p-overlayPanel>
        </ng-container>
        <p-skeleton *ngIf="!viewVersionList.length"></p-skeleton>
      </div>

      <!-- ADD/EDIT Version functionality is not implemented yet -->
      <!-- <div class="col-6 col-sm-6 p-4 mt-2 text-right">
      <ng-container *ngIf="hasPublishedOrRevokedVersion()">
        <button
          pButton
          type="button"
          class="p-button-outlined"
          style="width: 110px"
          [disabled]="
            selectedVersion && selectedVersion.status !== 'Draft'
          "
          (click)="triggerEditVersion()"
          icon="pi pi-file-edit"
          label="{{ 'MODULES.METRICS.EDIT' | translate }}"
        ></button>
      </ng-container>
      <button
        pButton
        type="button"
        class="p-button-outlined ml-2"
        icon="pi pi-plus-circle"
        (click)="triggerAddVersion()"
        label="{{ 'MODULES.METRICS.Add' | translate }}"
        style="width: 110px"
      ></button>
    </div> -->
    </div>

    <div class="grid">
      <div class="col-4 mb-1">
        <label class="fw-bold" translate>
          MODULES.VIEW_METRICS.VERSION_NAME
        </label>

        <div class="mt-1">
          <span *ngIf="createMetricForm.get('versionName')?.value">
            {{ createMetricForm.get('versionName')?.value }}
          </span>
          <p-skeleton
            *ngIf="!createMetricForm.get('versionName')?.value"
          ></p-skeleton>
        </div>
      </div>

      <!-- Target -->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate> MODULES.VIEW_METRICS.TARGET </label>
        <div class="mt-1">
          {{ createMetricForm.get('target')?.value }}
          <p-skeleton
            *ngIf="!createMetricForm.get('target')?.value"
          ></p-skeleton>
        </div>
      </div>

      <!-- Expected -->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate> MODULES.VIEW_METRICS.EXPECTED </label>
        <div class="mt-1">
          {{ createMetricForm.get('expected')?.value }}
          <p-skeleton
            *ngIf="!createMetricForm.get('expected')?.value"
          ></p-skeleton>
        </div>
      </div>

      <!-- Start date-->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate>
          MODULES.VIEW_METRICS.START_DATE
        </label>
        <div class="mt-1">
          {{ createMetricForm.get('startDate')?.value | date : 'yyyy-MM-dd' }}
          <p-skeleton
            *ngIf="!createMetricForm.get('startDate')?.value"
          ></p-skeleton>
        </div>
      </div>

      <!-- End Date -->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate> MODULES.VIEW_METRICS.END_DATE </label>
        <div class="mt-1">
          {{ createMetricForm.get('endDate')?.value | date : 'yyyy-MM-dd' }}
          <p-skeleton
            *ngIf="!createMetricForm.get('endDate')?.value"
          ></p-skeleton>
        </div>
      </div>

      <!-- version statement -->
      <div class="col-4 mb-1">
        <label class="fw-bold" translate> MODULES.CONTRACTS.NOTES</label>
        <div class="mt-1">
          {{ createMetricForm.get('statement')?.value }}
          <p-skeleton
            *ngIf="!createMetricForm.get('statement')?.value"
          ></p-skeleton>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Edit Metric Form -->
<ng-container *ngIf="isEditVersionEnabled">
  <app-edit-metric-catalog [viewMetricData]="viewData"></app-edit-metric-catalog>
</ng-container>

<!-- btn section -->
<ng-container *ngIf="!isEditVersionEnabled && !isAddVersionEnabled">
  <div class="right-sidebar-footer">
    <div class="w-100 d-flex">
      <!-- close btn -->
      <button
        pButton
        type="button"
        class="p-button-outlined me-auto"
        style="width: 110px"
        label="{{ 'BUTTONS.CLOSE' | translate }}"
        (click)="closeViewMetric()"
      ></button>

      <!-- ADD/EDIT Version functionality is not implemented yet -->
      <!-- publish btn -->
      <!-- <ng-container *ngIf="selectedVersion && selectedVersion.status === 'Draft'">
          <button
            pButton
            type="submit"
            label="{{ 'MODULES.CREATE_METRIC.PUBLISH' | translate }}"
            [disabled]="!metricPermission.hasPublishMetricVersion()"
            class="p-button"
            style="width: 110px"
          ></button>
        </ng-container> -->

      <!-- ADD/EDIT Version functionality is not implemented yet -->
      <!-- revoke btn -->
      <!-- <ng-container *ngIf="selectedVersion && selectedVersion.status === 'Published'">
          <button
            pButton
            type="submit"
            class="p-button-publish"
            label="{{ 'MODULES.CREATE_METRIC.REVOKE' | translate }}"
            [disabled]="!metricPermission.hasRevokeMetricVersion()"
            style="width: 110px"
          ></button>
        </ng-container> -->
    </div>
  </div>
</ng-container>
