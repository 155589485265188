<ng-container *ngIf="!dynamicForm?.controls">
  <div class="grid">
    <div class="col-4 mb-1" *ngFor="let item of [].constructor(6)">
      <p-skeleton></p-skeleton>
    </div>
  </div>
</ng-container>

<form [formGroup]="dynamicForm" *ngIf="dynamicForm?.controls">
  <div class="grid mt-2">
    <ng-container *ngFor="let paramfield of metricParamsInfo">
      <!-- Text Input -->
      <ng-container *ngIf="paramfield.objectFieldType === 'String'">
        <div class="col-4">
          <label
            class="fw-bold mb-1"
            for="text-input"
            [ngClass]="{ required: paramfield.isMandatory }"
          >
            {{ paramfield.name }}
          </label>

          <span class="w-100 my-1">
            <input
              class="w-100 p-inputtext-sm height-40"
              type="text"
              pInputText
              placeholder="{{ 'PLACEHOLDER.TYPE_HERE' | translate }}"
              [formControlName]="paramfield.name"
            />
          </span>

          <div *ngIf="dynamicForm.get(paramfield.name)?.touched">
            <small
              *ngIf="dynamicForm.get(paramfield.name)?.errors?.['required']"
              class="text-danger"
            >
              {{ 'VALIDATION.REQUIRED' | translate }}
            </small>

            <small
              *ngIf="dynamicForm.get(paramfield.name)?.errors?.['maxlength']"
              class="text-danger"
            >
              {{
                'VALIDATION.MAXLENGTH'
                  | translate : { requiredLength: paramfield.fieldLength }
              }}
            </small>
          </div>
        </div>
      </ng-container>

      <!-- Number Input -->
      <ng-container *ngIf="paramfield.objectFieldType === 'Number'">
        <div class="col-4">
          <label
            class="fw-bold mb-1"
            for="number-input"
            [ngClass]="{ required: paramfield.isMandatory }"
          >
            {{ paramfield.name }}
          </label>

          <span class="w-100 my-1">
            <p-inputNumber
              mode="decimal"
              [minFractionDigits]="1"
              [maxFractionDigits]="4"
              placeholder="{{ 'PLACEHOLDER.TYPE_HERE' | translate }}"
              [formControlName]="paramfield.name"
            >
            </p-inputNumber>
          </span>
          <div *ngIf="dynamicForm.get(paramfield.name)?.touched">
            <small
              *ngIf="dynamicForm.get(paramfield.name)?.errors?.['required']"
              class="text-danger"
            >
              {{ 'VALIDATION.REQUIRED' | translate }}
            </small>

            <small
              *ngIf="dynamicForm.get(paramfield.name)?.errors?.['pattern']"
              class="text-danger"
            >
              {{ 'VALIDATION.INVALID_PATTERN' | translate }}
            </small>
          </div>
        </div>
      </ng-container>

      <!-- Date Input -->
      <ng-container
        *ngIf="
          ['Date and Time', 'Date', 'Month', 'Year'].includes(
            paramfield.objectFieldType
          )
        "
      >
        <div class="col-4">
          <label
            class="fw-bold mb-1"
            for="calendar-input"
            [ngClass]="{ required: paramfield.isMandatory }"
          >
            {{ paramfield.name }}
          </label>
          <span class="w-100 my-1">
            <p-calendar
              styleClass="w-100 height-40"
              type="date"
              placeholder="{{ 'PLACEHOLDER.SELECT_DATE' | translate }}"
              [formControlName]="paramfield.name"
              [showIcon]="true"
              [readonlyInput]="true"
              [view]="getViewType(paramfield.objectFieldType)"
              [dateFormat]="getDateFormat(paramfield.objectFieldType)"
              [showTime]="
                paramfield.objectFieldType === 'Date and Time' ? true : false
              "
              [showSeconds]="true"
              appendTo="body"
            >
            </p-calendar>
          </span>
          <div *ngIf="dynamicForm.get(paramfield.name)?.touched">
            <small
              *ngIf="dynamicForm.get(paramfield.name)?.errors?.['required']"
              class="text-danger"
            >
              {{ 'VALIDATION.REQUIRED' | translate }}
            </small>
          </div>
        </div>
      </ng-container>

      <!-- Time Input (Interval) -->
      <ng-container *ngIf="paramfield.objectFieldType === 'Interval'">
        <div class="col-4">
          <label
            class="fw-bold mb-1"
            for="calendar-input"
            [ngClass]="{ required: paramfield.isMandatory }"
          >
            {{ paramfield.name }}
          </label>
          <span class="w-100 my-1">
            <p-calendar
              styleClass="w-100 height-40"
              type="date"
              placeholder="{{ 'PLACEHOLDER.SELECT_INTERVAL' | translate }}"
              [formControlName]="paramfield.name"
              [showIcon]="true"
              [showSeconds]="true"
              [timeOnly]="true"
              appendTo="body"
            >
            </p-calendar>
          </span>
          <div *ngIf="dynamicForm.get(paramfield.name)?.touched">
            <small
              *ngIf="dynamicForm.get(paramfield.name)?.errors?.['required']"
              class="text-danger"
            >
              {{ 'VALIDATION.REQUIRED' | translate }}
            </small>
          </div>
        </div>
      </ng-container>

      <!-- Dropdown (ValueSet) -->
      <ng-container *ngIf="paramfield.objectFieldType === 'Value Set'">
        <div class="col-4">
          <label
            class="fw-bold mb-1"
            for="title"
            [ngClass]="{ required: paramfield.isMandatory }"
          >
            {{ paramfield.name }}
          </label>
          <span class="w-100 my-1">
            <p-dropdown
              styleClass="w-full height-40"
              placeholder="{{ 'PLACEHOLDER.SELECT' | translate }}"
              [formControlName]="paramfield.name"
              [options]="valueSetList[paramfield.valueSetId]"
              optionLabel="title"
              [autoDisplayFirst]="false"
            >
            </p-dropdown>
          </span>
          <div *ngIf="dynamicForm.get(paramfield.name)?.touched">
            <small
              *ngIf="dynamicForm.get(paramfield.name)?.errors?.['required']"
              class="text-danger"
            >
              {{ 'VALIDATION.REQUIRED' | translate }}
            </small>
          </div>
        </div>
      </ng-container>

      <!-- Dropdown (Calendar) -->
      <ng-container *ngIf="paramfield.objectFieldType === 'Calendar'">
        <div class="col-4">
          <label
            class="fw-bold mb-1"
            for="title"
            [ngClass]="{ required: paramfield.isMandatory }"
          >
            {{ paramfield.name }}
          </label>
          <span class="w-100 my-1">
            <p-dropdown
              styleClass="w-full height-40"
              placeholder="{{ 'PLACEHOLDER.SELECT' | translate }}"
              [formControlName]="paramfield.name"
              [options]="calendarTypeList[paramfield.calendarGroupId]"
              optionLabel="name"
              [autoDisplayFirst]="false"
            >
            </p-dropdown>
          </span>
          <div *ngIf="dynamicForm.get(paramfield.name)?.touched">
            <small
              *ngIf="dynamicForm.get(paramfield.name)?.errors?.['required']"
              class="text-danger"
            >
              {{ 'VALIDATION.REQUIRED' | translate }}
            </small>
          </div>
        </div>
      </ng-container>

      <!-- Tree select -->
      <ng-container *ngIf="paramfield.objectFieldType === 'Hierarchy'">
        <div class="col-4">
          <label
            class="fw-bold mb-1"
            for="paramfield.name"
            [ngClass]="{ required: paramfield.isMandatory }"
          >
            {{ paramfield.name }}
          </label>
          <span class="w-100 my-1">
            <p-treeSelect
              [placeholder]="paramfield.name"
              [formControlName]="paramfield.name"
              containerStyleClass="w-100 height-40"
              [options]="hierarchyList"
              filterBy="name"
              [filter]="true"
              emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}"
            >
              <ng-template let-node pTemplate="default">
                <div class="d-flex w-100">
                  <i
                    [ngClass]="
                      node.expanded
                        ? 'pi pi-folder-open mr-2 my-auto tree-folder-icon'
                        : 'pi pi-folder mr-2 my-auto tree-folder-icon'
                    "
                  ></i>
                  <div class="my-auto">
                    <h6>{{ node.name }}</h6>
                  </div>
                </div>
              </ng-template>

              <!-- to display selected value -->
              <ng-template let-node pTemplate="value">
                {{
                  node?.name ? node?.name : ('PLACEHOLDER.SELECT' | translate)
                }}
              </ng-template>
            </p-treeSelect>
          </span>
          <div *ngIf="dynamicForm.get(paramfield.name)?.touched">
            <small
              *ngIf="dynamicForm.get(paramfield.name)?.errors?.['required']"
              class="text-danger"
            >
              {{ 'VALIDATION.REQUIRED' | translate }}
            </small>
          </div>
        </div>
      </ng-container>

      <!-- textarea -->
      <!-- <ng-container *ngIf="paramfield.objectFieldType === 'Rich Text'">
        <div class="col-4">
          <label class="fw-bold mb-1" for="textarea" [ngClass]="{ required: paramfield.isMandatory }">
            {{ paramfield.name }}
          </label>
          <span class="w-100 my-1">
            <textarea class="w-100 textarea-field" pInputTextarea [autoResize]="true"
              placeholder="{{ 'PLACEHOLDER.TYPE_HERE' | translate }}"
              [formControlName]="paramfield.name"
            ></textarea>
          </span>
          <div *ngIf="dynamicForm.get(paramfield.name)?.touched">
            <small
              *ngIf="dynamicForm.get(paramfield.name)?.errors?.['required']"
              class="text-danger"
            >
              {{ 'VALIDATION.REQUIRED' | translate }}
            </small>
            <small
              *ngIf="dynamicForm.get(paramfield.name)?.errors?.['maxlength']"
              class="text-danger"
            >
              {{
                'VALIDATION.MAXLENGTH'
                  | translate : { requiredLength: paramfield.fieldLength }
              }}
            </small>
          </div>
        </div>
      </ng-container> -->
    </ng-container>
  </div>
</form>
